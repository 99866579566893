
<div [class.video-page-container]="!theatre" [class.standard-placeholder]="!theatre" [class.theatre-placeholder]="theatre" class="p-0">
    <ng-container *ngIf="combined$ | async as data">
        <shaka-player *ngIf="data.video.dashUrl || data.video.fpsHlsUrl" [url]="data.video.dashUrl" [previewsUrl]="data.video.previewsUrl" [widevineUrl]="data.video.widevineUrl"
                      [fairplayUrl]="data.video.fairPlayUrl" [fpsHlsUrl]="data.video.fpsHlsUrl" [poster]="data.video.poster"
                      [videoId]="data.video.dashVideoId ?? data.video.videoId" [uid]="data.video.uid" [autoplay]="autoPlay" [startTimeMs]="data.video.startTimeMs"
                      [textTracks]="data.video.subtitles" (reportMedia)="showReportReasons = true" (theatre)="theatre = $event" [maxBitrate]="data.bitrate">
        </shaka-player>
        <videojs *ngIf="(safari === true && data.video.hlsUrl) || (data.video.hlsUrl && !data.video.dashUrl)" [url]="data.video.hlsUrl" [poster]="data.video.poster"
                 [videoId]="data.video.hlsVideoId ?? data.video.videoId" [type]="data.video.type" [autoplay]="autoPlay"
                 [startTimeMs]="data.video.startTimeMs"></videojs>
    </ng-container>
</div>




