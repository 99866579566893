import { Component, OnInit } from '@angular/core';
import { ChannelsService } from 'src/app/services/channels.service';
import { ActivatedRoute } from '@angular/router';
import { StorageManagerService } from 'src/app/services/storage-manager.service';
import { AppComponent } from 'src/app/app.component';
import { WatchHistoryService } from '../../services/watch-history.service';

@Component({
    selector: 'app-channel',
    templateUrl: './channel.component.html',
    styleUrls: ['./channel.component.scss']
})
export class ChannelComponent implements OnInit {

    channelId: string;
    channel: any;
    media: any[];

    order: string;

    constructor(
        private route: ActivatedRoute,
        private channelService: ChannelsService,
        private storageManager: StorageManagerService,
        private app: AppComponent,
        private watchHistoryService: WatchHistoryService) {
    }

    ngOnInit(): void {
        this.route.params.subscribe(() => {
            this.channelLoad();
        });

        this.storageManager.setPage('channel');
    }

    channelLoad() {
        this.channelId = this.route.snapshot.paramMap.get('id');

        this.channelService.getChannels(true, !this.app.hasSubscription()).subscribe(res => {
            this.channel = res.find(f => f.id.toString() == this.channelId || f.slug == this.channelId);
            this.media = this.channel.media;

            this.order = this.storageManager.playlistOrder();

            if (this.storageManager.playlistOrder() == 'asc') {
                this.media.reverse();
            }

            this.storageManager.setCookie('lastChannel', this.channelId);
            this.storageManager.setCookie('channel-' + this.channelId, this.channel);
        });

        this.watchHistoryService.refreshWatchHistory();
    }

    onSearchChange(searchValue: string) {
        if (searchValue) {
            this.media = this.channel.media.filter(f => f.title.toLowerCase().indexOf(searchValue.toLowerCase()) > -1);
        } else {
            this.media = this.channel.media;
        }
        if (this.storageManager.playlistOrder() == 'asc') {
            this.media.reverse();
        }
    }

    changeOrder() {
        if (this.storageManager.playlistOrder() == 'asc') {
            this.storageManager.setPlaylistOrder('desc');
            this.media.reverse();
        } else {
            this.storageManager.setPlaylistOrder('asc');
            this.media.reverse();
        }
        this.storageManager.setCookie('channel-' + this.channelId, this.channel);
        this.order = this.storageManager.playlistOrder();
    }
}
