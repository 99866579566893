<!--<div class="main-full">-->
<!--    <app-video-player [mediaId]="mediaId" [autoPlay]="autoPlay" (safariHls)="safariHls = $event" (videoLoaded)="videoLoaded = $event"></app-video-player>-->

<!--    <div class="container">-->
<!--        <div class="row">-->
<!--            <div class="col-12 col-lg-8">-->
<!--                <div class="row mt-2">-->
<!--                    <app-video-description-->
<!--                            [mediaId]="mediaId"-->
<!--                            [videoLoaded]="videoLoaded"-->
<!--                            [safariHls]="safariHls"-->
<!--                            (reportClicked)="showReportReasons = true"-->
<!--                    ></app-video-description>-->
<!--                    <app-video-comments [mediaId]="mediaId"></app-video-comments>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-4 d-none d-lg-block" *ngIf="config.remoteConfig.up_next_enabled">-->
<!--                <app-recommended-list [mediaId]="mediaId"-->
<!--                                      (autoPlayToggled)="autoPlayToggled($event)"></app-recommended-list>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<div class="main-full">
    <app-video-player [mediaId]="mediaId" [autoPlay]="autoPlay" (safariHls)="safariHls = $event" (videoLoaded)="videoLoaded = $event"></app-video-player>

    <div class="video-page-container video-page-lower">
        <div class="w-100">
            <app-video-description
                    [mediaId]="mediaId"
                    [videoLoaded]="videoLoaded"
                    [safariHls]="safariHls"
                    (reportClicked)="showReportReasons = true"
            ></app-video-description>
            <hr/>
            <app-video-comments [mediaId]="mediaId"></app-video-comments>
        </div>
        <div class="d-none d-lg-block recommended-list">
            <app-recommended-list [mediaId]="mediaId" (autoPlayToggled)="autoPlayToggled($event)"></app-recommended-list>
        </div>

    </div>
</div>

<app-modal-media-report *ngIf="showReportReasons" (close)="showReportReasons = false" [mediaId]="mediaId">
</app-modal-media-report>
