import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';

import { AppComponent } from 'src/app/app.component';

import { Chat } from 'src/app/models/chat';

import { ChatService } from 'src/app/services/chat.service';
import { VideoService } from 'src/app/services/video.service';
import { StreamReport } from 'src/app/models/stream-report';
import { EmojiCustom } from '../../models/emoji-custom';
import { environment } from '../../../environments/environment';
import { RemoteConfigService } from '../../services/remote-config.service';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {

    container: HTMLElement;
    video: HTMLElement;
    viewers: number = 0;

    @ViewChild('scrollMe') private chatContainer: ElementRef;

    hideEmoji: boolean = true;

    customEmojis: EmojiCustom[];

    includes = [
        'search',
        'recent',
        'custom',
        'people',
        'symbols'
    ];

    translations = {
        search: 'Search',
        emojilist: 'List of emoji',
        notfound: 'No Emoji Found',
        clear: 'Clear',
        categories: {
            search: 'Search Results',
            recent: 'Frequently Used',
            custom: environment.name,
            people: 'Smileys & People',
            nature: 'Animals & Nature',
            foods: 'Food & Drink',
            activity: 'Activity',
            places: 'Travel & Places',
            objects: 'Objects',
            symbols: 'Symbols',
            flags: 'Flags',
        },
        skintones: {
            1: 'Default Skin Tone',
            2: 'Light Skin Tone',
            3: 'Medium-Light Skin Tone',
            4: 'Medium Skin Tone',
            5: 'Medium-Dark Skin Tone',
            6: 'Dark Skin Tone',
        }
    };


    current: any;

    autoscroll: boolean = true;
    allowedToSend: boolean = true;
    chat: Chat = new Chat();
    comments: any[] = [];

    mediaId: string;

    constructor(
        private app: AppComponent,
        private chatService: ChatService,
        private videoService: VideoService,
        db: AngularFireDatabase,
        public config: RemoteConfigService) {

        this.mediaId = 'live';

        db.list('chat-' + this.mediaId, ref => ref.limitToLast(100)).snapshotChanges().subscribe(data => {
            if (data) {

                let updatedComments = [];
                data.map(dataAction => {
                    let chat = <any>dataAction.payload.val();
                    chat.id = dataAction.key;
                    updatedComments.push(chat);
                });
                this.comments = updatedComments;
            }
        });
    }

    ngOnInit() {
        this.container = document.getElementById('chat-container');
        this.video = document.getElementById('video');
        this.chatService.getEmojis().subscribe(emojis => {
            this.customEmojis = emojis;
        });

        this.updateViewerCount();

        const interval = setInterval(this.updateViewerCount, 30000);
    }

    updateViewerCount() {
        {
            if (!this.video) {
                this.videoService.reportStreamProgress(
                    'live-stream',
                    0,
                    new StreamReport(0, 0, 'StreamProgress')
                ).subscribe(res => {
                    this.videoService.setLiveCount(res.viewers);
                });
            }
        }
    }

    send() {
        this.chat.message = this.chat.message.trim();
        if (!this.allowedToSend || this.chat.message.trim().length == 0) {
            return;
        }
        setTimeout(() => {
            this.allowedToSend = true;
        }, 500);
        this.chatService.sendMessage(this.mediaId, this.chat, null).subscribe();
        this.allowedToSend = false;
        this.chat = new Chat();
    }

    scrollToBottom(): void {
        if (this.autoscroll) {
            try {
                if (this.current !== this.chatContainer.nativeElement.scrollHeight) {
                    this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
                    this.current = this.chatContainer.nativeElement.scrollHeight;
                }
            } catch (err) {
            }
        }
    }

    getVideoHeight() {
        if (this.video) {
            return this.video.clientHeight - 75 + 'px';
        }
        return '85vh';
    }

    getLiveCount(): number {
        return this.videoService.getLiveCount();
    }


    emoji() {
        if (this.hideEmoji === true) {
            this.hideEmoji = false;
        } else {
            this.hideEmoji = true;
        }
    }

    addEmoji(event) {
        if (event.emoji.native) {
            if (this.chat.message) {
                this.chat.message += event.emoji.native;
            } else {
                this.chat.message = event.emoji.native;
            }
        } else {
            if (this.chat.message) {
                this.chat.message += event.emoji.colons;
            } else {
                this.chat.message = event.emoji.colons;
            }
        }
    }
}
