import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { environment } from 'src/environments/environment';

/* Third Party */
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DeferLoadModule } from '@trademe/ng-defer-load';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { TextareaAutosizeModule } from 'ngx-textarea-autosize';
import { MarkdownModule } from 'ngx-markdown';

// angular fire - NEW SDK (v7)
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { provideAppCheck, ReCaptchaEnterpriseProvider, initializeAppCheck } from '@angular/fire/app-check';

// angular fire - COMPATABILITY MODE
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthGuardModule } from '@angular/fire/compat/auth-guard';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';

// pages
import { BrandsComponent } from './pages/brands/brands.component';
import { HomeComponent } from './pages/home/home.component';
import { VideoComponent } from './pages/video/video.component';

// components
import { CommentReadComponent } from './components/comment-read/comment-read.component';
import { CommentWriteComponent } from './components/comment-write/comment-write.component';
import { FooterComponent } from './components/footer/footer.component';
import { MenuComponent } from './components/menu/menu.component';
import { RecommendedListComponent } from './components/recommended-list/recommended-list.component';
import { ScheduleThumbComponent } from './components/schedule-thumb/schedule-thumb.component';
import { SubscriptionRowComponent } from './components/subscription-row/subscription-row.component';
import { VideojsComponent } from './components/videojs/videojs.component';

// modals
import { ModalLoadingComponent } from './modals/modal-loading/modal-loading.component';
import { ModalLoadingComponentComponent } from './modals/modal-loading-component/modal-loading-component.component';

// pipes
// modules
// services
import { MessagingService } from './services/messaging.service';
import { TokenInterceptorService } from './services/interceptors/token-interceptor.service';
import { LiveComponent } from './pages/live/live.component';
import { ChatComponent } from './components/chat/chat.component';
import { ChatReadComponent } from './components/chat-read/chat-read.component';
import { DisableControlDirective } from './directives/disable-control.directive';
import { LiveMenuComponent } from './components/live-menu/live-menu.component';
import { ReversePipe } from './pipes/reverse';
import { CancelComponent } from './pages/cancel/cancel.component';
import { SuccessComponent } from './pages/success/success.component';
import { RateComponent } from './components/rate/rate.component';
import { UserRecommendationsComponent } from './components/user-recommendations/user-recommendations.component';
import { ChannelComponent } from './pages/channel/channel.component';
import { SearchComponent } from './pages/search/search.component';
import { UpNextSwitchComponent } from './components/up-next-switch/up-next-switch.component';
import { VideojsLiveComponent } from './components/videojs-live/videojs-live.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { LiveChatComponent } from './pages/live-chat/live-chat.component';
import { PushNotificationComponent } from './components/push-notification/push-notification.component';
import { SafePipe } from './pipes/safe';
import { ContactComponent } from './routes/home/contact/contact.component';
import { EmailPreferencesComponent } from './components/email-preferences/email-preferences.component';
import { ContributePointsComponent } from './components/contribute-points/contribute-points.component';
import { MobileAppsComponent } from './components/mobile-apps/mobile-apps.component';
import { SuccessCardUpdateComponent } from './pages/success-card-update/success-card-update.component';
import { ShakaPlayerComponent } from './components/shaka-player/shaka-player.component';
import { ImageSliderComponent } from './components/image-slider/image-slider.component';
import { PurchaseSuccessComponent } from './pages/purchase-success/purchase-success.component';
import { PurchaseCancelledComponent } from './pages/purchase-cancelled/purchase-cancelled.component';
import { UiKitComponent } from './pages/ui-kit/ui-kit.component';
import { OrBlockComponent } from './components/ui-kit/or-block/or-block.component';
import { ShowsComponent } from './pages/shows/shows.component';
import { MakersplaceComponent } from './pages/makersplace/makersplace.component';
import { HeroBannerComponent } from './components/hero-banner/hero-banner.component';
import { ShakaPlayerSimpleComponent } from './components/shaka-player-simple/shaka-player-simple.component';
import { SimpleAlertComponent } from './components/simple-alert/simple-alert.component';
import { SmushBrethrenComponent } from './pages/smush-brethren/smush-brethren.component';
import { VideoHeaderComponent } from './components/section/video-header/video-header.component';
import { LeftTextComponent } from './components/section/left-text/left-text.component';
import { RightTextComponent } from './components/section/right-text/right-text.component';
import { FinalComponent } from './components/section/final/final.component';
import { VideoHeaderRightComponent } from './components/section/video-header-right/video-header-right.component';
import { ModalSimpleShakaComponent } from './modals/modal-simple-shaka/modal-simple-shaka.component';
import { SmushDownloadComponent } from './pages/access-flow/smush-download/smush-download.component';
import { IvsLiveComponent } from './components/ivs-live/ivs-live.component';
import { LearnMoreComponent } from './pages/learn-more/learn-more.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import {
    SubscriptionManagementV2Component
} from './components/subscription-management-v2/subscription-management-v2.component';
import {
    SubscriptionRowV2Component
} from './components/subscription-management-v2/subscription-row-v2/subscription-row-v2.component';
import { CardRowV2Component } from './components/subscription-management-v2/card-row-v2/card-row-v2.component';
import { PaymentRowV2Component } from './components/subscription-management-v2/payment-row-v2/payment-row-v2.component';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { ContinueWatchingComponent } from './components/continue-watching/continue-watching.component';
import { VideoThumbV2Component } from './components/video-thumb-v2/video-thumb-v2.component';
import { ChannelSliderV2Component } from './components/channel-slider-v2/channel-slider-v2.component';
import { ChannelHeaderComponent } from './components/channel-header/channel-header.component';
import { ShowThumbComponent } from './components/show-thumb/show-thumb.component';
import { UserPlaylistComponent } from './components/user-playlist/user-playlist.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import {
    HeroBannerStaticImageComponent
} from './components/hero-banner-static-image/hero-banner-static-image.component';
import { BaseRerouteComponent } from './pages/base-reroute/base-reroute.component';
import { AccountV2Component } from './pages/account-v2/account-v2/account-v2.component';
import { DiscountCodeComponent } from './pages/account-v2/discount-code/discount-code.component';
import { BadgesComponent } from './pages/account-v2/badges/badges.component';
import {
    ChangeDisplayNameV2Component
} from './pages/account-v2/change-display-name-v2/change-display-name-v2.component';
import { ChangeEmailV2Component } from './pages/account-v2/change-email-v2/change-email-v2.component';
import { ChangePasswordV2Component } from './pages/account-v2/change-password-v2/change-password-v2.component';
import { EmailPreferencesV2Component } from './pages/account-v2/email-preferences-v2/email-preferences-v2.component';
import { EnvironmentService } from './services/environment.service';
import { CancelMembershipComponent } from './pages/account-v2/cancel-membership/cancel-membership.component';
import { RedirectGuard } from './guards/redirect.guard';
import { SmartTvComponent } from './pages/account-v2/smart-tv/smart-tv.component';
import { SafeHtmlPipe } from './pipes/safeHtml';
import { PaypalButtonV2Component } from './components/paypal/paypal-button-v2/paypal-button-v2.component';
import { PlaylistComponent } from './components/section/playlist/playlist.component';
import { ModalLegalDocumentsComponent } from './modals/modal-legal-documents/modal-legal-documents.component';
import { LegalDocumentComponent } from './pages/legal-document/legal-document.component';
import { VideoThumbSimpleComponent } from './components/video-thumb-simple/video-thumb-simple.component';
import { CountryBlockedComponent } from './components/country-blocked/country-blocked.component';
import { HeroCarouselComponent } from './components/hero-carousel/hero-carousel.component';
import { VideoThumbOpenComponent } from './components/video-thumb-open/video-thumb-open.component';
import { LatestVideosComponent } from './components/latest-videos/latest-videos.component';
import { HeroCarouselBannerComponent } from './components/hero-carousel-banner/hero-carousel-banner.component';
import { SeriesViewV2Component } from './pages/series-view-v2/series-view-v2.component';
import { SeriesViewMediaItemV2Component } from './components/series-view-media-item-v2/series-view-media-item-v2.component';
import { VoucherComponent } from './pages/voucher/voucher.component';
import { EnterVoucherCodeComponent } from './components/enter-voucher-code/enter-voucher-code.component';
import { CancelMembershipReasonComponent } from './pages/account-v2/cancel-membership-reason/cancel-membership-reason.component';
import { PaypalPaymentPendingComponent } from './pages/paypal-payment-pending/paypal-payment-pending.component';
import { TranslatePipe } from './pipes/translate.pipe';
import { ChangeLanguageComponent } from './pages/account-v2/change-language/change-language/change-language.component';
import { AutoFocusDirective } from './directives/autofocus.directive';
import { BlankComponent } from './pages/blank/blank.component';
import { ClickOutsideDirective } from './directives/clickoutside.directive';
import { SeasonBannerComponent } from './components/season-banner/season-banner.component';
import { SignupEmailPreferencesComponent } from './pages/account-v2/signup-email-preferences/signup-email-preferences.component';
import { WatchlistComponent } from './pages/watchlist/watchlist.component';
import { NgxImageCompressService } from 'ngx-image-compress';
import { PaymentComponent } from './pages/access-flow/payment/payment.component';
import { TitleComponent } from './components/ui-kit/title/title.component';
import { BrandButtonsComponent } from './components/ui-kit/brand-buttons/brand-buttons.component';
import { StripeCheckoutComponent } from './pages/access-flow/checkouts/stripe-checkout/stripe-checkout.component';
import { CompletePaymentComponent } from './pages/access-flow/complete-payment/complete-payment.component';
import { RegisterComponent } from './pages/access-flow/register/register.component';
import { LoginComponent } from './pages/access-flow/login/login.component';
import { ForgottenPasswordComponent } from './pages/access-flow/forgotten-password/forgotten-password.component';
import { ForgottenPasswordRecoveryComponent } from './pages/access-flow/forgotten-password-recovery/forgotten-password-recovery.component';
import { DeveloperPageComponent } from './pages/developer-page/developer-page.component';
import { ModalMediaReportComponent } from './modals/modal-media-report/modal-media-report.component';
import { SomethingWrongComponent } from './pages/access-flow/something-wrong/something-wrong.component';
import { PrivacyKoreanComponent } from './pages/privacy-korean/privacy-korean.component';
import { VoucherV2Component } from './pages/account-v2/voucher-v2/voucher-v2.component';
import { EnterVoucherCodeV2Component } from './pages/account-v2/enter-voucher-code-v2/enter-voucher-code-v2.component';
import { PaymentMethodV2Component } from './pages/account-v2/payment-method-v2/payment-method-v2.component';
import { ProfilePictureV2Component } from './pages/account-v2/profile-picture-v2/profile-picture-v2.component';
import { PaymentHistoryV2Component } from './pages/account-v2/payment-history-v2/payment-history-v2.component';
import { PointHistoryV2Component } from './pages/account-v2/point-history-v2/point-history-v2.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { ChangePlanComponent } from './pages/account-v2/change-plan/change-plan.component';
import { CustomMessageComponent } from './pages/custom-message/custom-message.component';
import { ProjectsVsComponent } from './pages/projects-vs/projects-vs.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { SwipeDirective } from './directives/swipe.directive';
import { SmartTvCodeComponent } from './pages/account-v2/smart-tv-code/smart-tv-code.component';
import { VerifyEmailComponent } from './pages/access-flow/verify-email/verify-email.component';
import { RemoteConfigService } from './services/remote-config.service';
import { ChatPipe } from './pipes/chat.pipe';
import { DeleteAccountComponent } from './pages/account-v2/delete-account/delete-account.component';
import { NgxCookiebotConfig, NgxCookiebotModule } from '@halloverden/ngx-cookiebot';
import { Router, RouterModule } from '@angular/router';
import { UnderConstructionComponent } from './pages/under-construction/under-construction.component';
import { ChangeEmailCompleteComponent } from './pages/account-v2/change-email-complete/change-email-complete.component';
import { PasswordFieldComponent } from './components/ui-kit/password-field/password-field.component';
import { SubtitleSettingsComponent } from './pages/account-v2/subtitle-settings/subtitle-settings.component';
import { AccountService } from './services/account.service';
import { VideoCommentsComponent } from './components/video-comments/video-comments.component';
import { VideoDescriptionComponent } from './components/video-description/video-description.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { VideoQualityComponent } from './pages/account-v2/video-quality/video-quality.component';

class CookiebotConfig extends NgxCookiebotConfig {
    cdn = 'com';
    blockingMode = 'auto';
    cbId = environment.cookiebotId;
    loadScript = true;
}

@NgModule({
    declarations: [
        AppComponent,

        // pages
        HomeComponent,

        // pipes
        ReversePipe,
        SafeHtmlPipe,

        // components,
        VideoComponent,
        VideojsComponent,
        ModalLoadingComponent,
        CommentWriteComponent,
        CommentReadComponent,
        ScheduleThumbComponent,
        BrandsComponent,
        MenuComponent,
        RecommendedListComponent,
        LiveComponent,
        ChatComponent,
        ChatReadComponent,
        DisableControlDirective,
        AutoFocusDirective,
        LiveMenuComponent,
        SubscriptionRowComponent,
        ModalLoadingComponentComponent,
        CancelComponent,
        SuccessComponent,
        FooterComponent,
        RateComponent,
        UserRecommendationsComponent,
        ChannelComponent,
        SearchComponent,
        UpNextSwitchComponent,
        VideojsLiveComponent,
        MaintenanceComponent,
        LiveChatComponent,
        PushNotificationComponent,
        SafePipe,
        ContactComponent,
        EmailPreferencesComponent,
        ContributePointsComponent,
        MobileAppsComponent,
        SuccessCardUpdateComponent,
        ShakaPlayerComponent,
        ImageSliderComponent,
        PurchaseSuccessComponent,
        PurchaseCancelledComponent,
        UiKitComponent,
        OrBlockComponent,
        ShowsComponent,
        MakersplaceComponent,
        HeroBannerComponent,
        ShakaPlayerSimpleComponent,
        SimpleAlertComponent,
        SmushBrethrenComponent,
        VideoHeaderComponent,
        LeftTextComponent,
        RightTextComponent,
        FinalComponent,
        VideoHeaderRightComponent,
        ModalSimpleShakaComponent,
        SmushDownloadComponent,
        IvsLiveComponent,
        LearnMoreComponent,
        NotFoundComponent,
        SubscriptionManagementV2Component,
        SubscriptionRowV2Component,
        CardRowV2Component,
        PaymentRowV2Component,
        ContinueWatchingComponent,
        VideoThumbV2Component,
        ChannelSliderV2Component,
        ChannelHeaderComponent,
        ShowThumbComponent,
        UserPlaylistComponent,
        HeroBannerStaticImageComponent,
        BaseRerouteComponent,
        AccountV2Component,
        DiscountCodeComponent,
        BadgesComponent,
        ChangeDisplayNameV2Component,
        ChangeEmailV2Component,
        ChangePasswordV2Component,
        EmailPreferencesV2Component,
        CancelMembershipComponent,
        SmartTvComponent,
        PaypalButtonV2Component,
        PlaylistComponent,
        ModalLegalDocumentsComponent,
        LegalDocumentComponent,
        VideoThumbSimpleComponent,
        CountryBlockedComponent,
        HeroCarouselComponent,
        VideoThumbOpenComponent,
        LatestVideosComponent,
        HeroCarouselBannerComponent,
        SeriesViewV2Component,
        SeriesViewMediaItemV2Component,
        VoucherComponent,
        EnterVoucherCodeComponent,
        CancelMembershipReasonComponent,
        PaypalPaymentPendingComponent,
        TranslatePipe,
        ChangeLanguageComponent,
        BlankComponent,
        ClickOutsideDirective,
        SeasonBannerComponent,
        SignupEmailPreferencesComponent,
        WatchlistComponent,
        RegisterComponent,
        LoginComponent,
        PaymentComponent,
        TitleComponent,
        BrandButtonsComponent,
        ForgottenPasswordComponent,
        StripeCheckoutComponent,
        CompletePaymentComponent,
        ForgottenPasswordRecoveryComponent,
        DeveloperPageComponent,
        ModalMediaReportComponent,
        SomethingWrongComponent,
        PrivacyKoreanComponent,
        EnterVoucherCodeV2Component,
        VoucherV2Component,
        PaymentMethodV2Component,
        ProfilePictureV2Component,
        PaymentHistoryV2Component,
        PointHistoryV2Component,
        ConfirmComponent,
        ChangePlanComponent,
        CustomMessageComponent,
        ProjectsVsComponent,
        LoadingSpinnerComponent,
        SwipeDirective,
        SmartTvCodeComponent,
        VerifyEmailComponent,
        ChatPipe,
        DeleteAccountComponent,
        UnderConstructionComponent,
        ChangeEmailCompleteComponent,
        PasswordFieldComponent,
        SubtitleSettingsComponent,
        VideoCommentsComponent,
        VideoDescriptionComponent,
        VideoPlayerComponent,
        VideoQualityComponent,
    ],
    imports: [
        // start of firebase
        // verison 7
        provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
        provideAuth(() => getAuth()),
        provideAppCheck(() => initializeAppCheck(getApp(), {
            provider: new ReCaptchaEnterpriseProvider(environment.recaptchaKey),
            isTokenAutoRefreshEnabled: true
        })),

        // angular fire compat
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireMessagingModule,
        AngularFireAuthGuardModule,
        AngularFireDatabaseModule,
        // end of firebase
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        DragDropModule,
        DropzoneModule,
        DeferLoadModule,
        FormsModule,
        HttpClientModule,
        ImageCropperModule,
        NgbModule,
        ReactiveFormsModule,
        PickerModule,
        ScrollToModule.forRoot(),
        SweetAlert2Module.forRoot(),
        TextareaAutosizeModule,
        MarkdownModule.forRoot(),
        NgxCookiebotModule.forRoot(CookiebotConfig),
    ],
    providers: [
        MessagingService,
        RedirectGuard,
        NgxImageCompressService,
        GoogleAnalyticsService,
        {
            // Auth Header Interceptor
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (envService: EnvironmentService) => () => envService.init(),
            deps: [EnvironmentService],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (config: RemoteConfigService, account: AccountService) => () => config.init().then(() => account.checkAuthenticated()),
            deps: [RemoteConfigService, AccountService, AppRoutingModule, RouterModule],
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
