import { Injectable } from '@angular/core';
import { AuthResponseV2 } from '../models/auth-response-v2';

@Injectable({
    providedIn: 'root'
})
export class StorageManagerService {

    device: 'ios' | 'android' = undefined;

    constructor() { }

    public get topComments(): boolean {
        const isTopComments = localStorage.getItem('topComments');
        return (isTopComments ?? 'false') === 'true';
    }

    public set topComments(value: boolean) {
        localStorage.setItem('topComments', JSON.stringify(value));
    }

    public getPlayerSize() {
        return sessionStorage.getItem('screen-size-setting');
    }

    public setPlayerSize(size: string) {
        sessionStorage.setItem('screen-size-setting', size);
    }

    public removePlayerSize() {
        sessionStorage.removeItem('screen-size-setting');
    }

    public playlistOrder() {
        return localStorage.getItem('playlist-order')
    }

    public setPlaylistOrder(order) {
        localStorage.setItem('playlist-order', order)
    }

    public upNextOrder() {
        return localStorage.getItem('up-next-order')
    }

    public setUpNextOrder(order) {
        localStorage.setItem('up-next-order', order)
    }

    public getPage() {
        return localStorage.getItem('page')
    }

    public setPage(page) {
        localStorage.setItem('page', page)
    }

    public getToken() {
        return localStorage.getItem('public-token')
    }

    public setToken(token) {
        localStorage.setItem('public-token', token)
    }

    public removeToken() {
        localStorage.removeItem('public-token')
    }

    public getSubscription() {
        return JSON.parse(localStorage.getItem('public-subscription'))
    }

    public setSubscription(subscription) {
        localStorage.setItem('public-subscription', JSON.stringify(subscription))
    }

    public removeSubscription() {
        localStorage.removeItem('public-subscription')
    }

    public getNotification() {
        return JSON.parse(localStorage.getItem('public-notification'))
    }

    public setNotification(notification) {
        localStorage.setItem('public-notification', JSON.stringify(notification))
    }

    public removeNotification() {
        localStorage.removeItem('public-notification')
    }

    public getEmail() {
        return localStorage.getItem('public-email')
    }

    public setEmail(email) {
        localStorage.setItem('public-email', email)
    }

    public removeEmail() {
        localStorage.removeItem('public-email')
    }

    public getAvatarUrl() {
        return localStorage.getItem('public-avatar-url')
    }

    public setAvatarUrl(url) {
        localStorage.setItem('public-avatar-url', url)
    }

    public setCookie(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value))
    }

    public getCookie(key: string) {
        return JSON.parse(localStorage.getItem(key))
    }

    public removeCookie(key: string) {
        localStorage.removeItem(key)
    }

    public setString(key: string, value: string) {
        localStorage.setItem(key, value)
    }

    public getString(key: string) {
        return localStorage.getItem(key)
    }

    public removeString(key: string) {
        localStorage.removeItem(key)
    }

    public getOverrideToken() {
        return sessionStorage.getItem('accessTokenOverride');
    }

    public setOverrideToken(token: string) {
        sessionStorage.setItem('accessTokenOverride', token);
    }

    public getAfterLoginUrl(): string | null {
        return sessionStorage.getItem('afterlogin');
    }

    public setAfterLoginUrl(url): void {
        sessionStorage.setItem('afterlogin', url);
    }

    public removeAfterLoginUrl(): void {
        sessionStorage.removeItem('afterlogin');
    }

    public getDevice(): 'ios' | 'android' {
        if (this.device === undefined) {
            this.device = localStorage.getItem('device') as ('ios' | 'android') ?? undefined;
        }

        return this.device;
    }

    public setDevice(device: 'ios' | 'android'): void {
        if (this.device === undefined) {
            localStorage.setItem('device', device);
            this.device = device;
        }
    }

    public getAuthTokenV2(): AuthResponseV2 {
        const str = localStorage.getItem('auth-v2');
        if (str) {
            return JSON.parse(str);
        }
        return undefined;
    }

    public setAuthTokenV2(auth: AuthResponseV2): void {
        localStorage.setItem('auth-v2', JSON.stringify(auth))
    }

    public removeAuthTokenV2(): void {
        localStorage.removeItem('auth-v2');
    }
}
