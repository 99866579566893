import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class Utils {

    static isValidEmail(email: string): boolean {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }

    // https://stackoverflow.com/questions/6108819/javascript-timestamp-to-relative-time-eg-2-seconds-ago-one-week-ago-etc-best
    static getTime(dateString: string) {
        const msPerMinute = 60 * 1000;
        const msPerHour = msPerMinute * 60;
        const msPerDay = msPerHour * 24;
        const msPerMonth = msPerDay * 30;
        const msPerYear = msPerDay * 365;
        const now = new Date();
        const next = Date.parse(dateString);

        const elapsed = now.getTime() - next;

        if (elapsed < msPerMinute) {
            return 'less than a minute ago';
        }

        else if (elapsed < msPerHour) {
            let value = Math.round(elapsed / msPerMinute)
            if (value == 1) {
                return value + ' minute ago';
            }
            else {
                return value + ' minutes ago';
            }
        }

        else if (elapsed < msPerDay) {
            let value = Math.round(elapsed / msPerHour)
            if (value == 1) {
                return value + ' hour ago';
            }
            else {
                return value + ' hours ago';
            }
        }

        else if (elapsed < msPerMonth) {
            let value = Math.round(elapsed / msPerDay)
            if (value == 1) {
                return value + ' day ago';
            }
            else {
                return value + ' days ago';
            }
        }

        else if (elapsed < msPerYear) {
            let value = Math.round(elapsed / msPerMonth)
            if (value == 1) {
                return value + ' month ago';
            }
            else {
                return value + ' months ago';
            }
        }

        else {
            let value = Math.round(elapsed / msPerYear)
            if (value == 1) {
                return value + ' year ago';
            }
            else if (value > 1) {
                return value + ' years ago';
            }
            else {
                return ""
            }
        }
    }

    static delay(delayMs: number): Promise<void> {
        return new Promise((resolve) => setTimeout(() => resolve(), delayMs))
    }

    static formatTimeUntilDHMS(targetDate: number): string {
        // Get today's date and time
        const now = new Date().getTime();

        // Find the distance between now and the count-down date
        const distance = targetDate - now;

        // Time calculations for days, hours, minutes and seconds
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (isNaN(days)) {
            return;
        }

        if (distance < 0) {
            return null;
        }

        // Display the result in the element with id="demo"
        let timeUntil = '';

        if (days > 0) {
            timeUntil = days + 'd ';
        }
        timeUntil += String(hours).padStart(2, '0') + 'h ' + String(minutes).padStart(2, '0') + 'm ' + String(seconds).padStart(2, '0') + 's';

        return timeUntil;
    }

    static isNullOrWhitespace(str: string): boolean {
        return str === null || str === undefined || str.trim() === '';
    }

}
