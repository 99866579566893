export interface Comment {
    id: string;
    avatarUrl: string;
    badges?: any;
    dateCreated: string;
    hash?: string;
    message: string;
    name: string;
    replies?: Comment[];
    displayName?: string;
    official: boolean;
    originalCommentId: number;
    uid: string;
    created?: string;
    badgeUrl?: string;
    comments?: Comment[];
    likes?: number;
    liked?: boolean;
}

export interface CommentResponse {
    comments: Comment[];
    lastPage: boolean;
}
