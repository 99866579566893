import { Component, OnDestroy, OnInit } from '@angular/core';
import { WebSocketService } from 'src/app/services/websocket.service';
import { ScreenSizeEnum } from '../../models/screen-size.enum';
import { Subject, takeUntil } from 'rxjs';
import { UiService } from '../../services/ui.service';

@Component({
    selector: 'app-live-menu',
    templateUrl: './live-menu.component.html',
    styleUrls: ['./live-menu.component.scss']
})
export class LiveMenuComponent implements OnInit, OnDestroy {

    isLive: boolean = false;

    countDownDate: number = null;
    timeUntilLs: string;
    isMobileView: boolean = false;

    private destroy = new Subject<void>();

    constructor(
        private webSocketService: WebSocketService,
        private ui: UiService,) {
        this.webSocketService.join('livestream-v2');
        this.webSocketService.liveStreamStatus.subscribe(res => {
            this.isLive = res.Live;
            this.countDownDate = new Date(res.Date).getTime();
            this.checkLiveCountdown();
        });

        this.ui.breakpointObservable
            .pipe(
                takeUntil(this.destroy)
            )
            .subscribe((screenSize: ScreenSizeEnum) => {
                this.isMobileView = screenSize === ScreenSizeEnum.XSmall;
            });
    }

    ngOnInit() {
    }

    checkLiveCountdown() {
        let intervalId = setInterval(() => {
            // Get today's date and time
            const now = new Date().getTime();

            // Find the distance between now and the count-down date
            const distance = this.countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            if (isNaN(days)) {
                return;
            }

            // Display the result in the element with id="demo"
            this.timeUntilLs = '';

            if (days > 0) {
                this.timeUntilLs = days + 'd ';
            }
            this.timeUntilLs += hours + 'h '
                + minutes + 'm';

            // If the count-down is finished, write some text
            if (distance < 0) {
                clearInterval(intervalId);
                this.timeUntilLs = null;
            }
        }, 2000);
    }

    ngOnDestroy() {
        this.webSocketService.leave('livestream-v2');
        this.destroy.next();
        this.destroy.complete();
    }
}
