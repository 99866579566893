import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';
import { webSocket } from 'rxjs/webSocket';
import { EnvironmentService } from './environment.service';
import { LiveStreamStatus } from '../models/live-stream-status';
import { ProjectPointsSummary } from '../models/ProjectPointsSummary';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class WebSocketService {

    pingInterval: any;

    pathsJoined: string[] = [];
    pathsToLeave: string[] = [];

    // these need to be moved
    private greenlightPointsSubject: Subject<ProjectPointsSummary[]> = new Subject();
    greenlightPoints: Observable<ProjectPointsSummary[]> = this.greenlightPointsSubject.asObservable();

    private greenlightPointsLimitSubject: Subject<any[]> = new Subject();
    greenlightPointsLimit: Observable<any[]> = this.greenlightPointsLimitSubject.asObservable();

    private liveStreamStatusSubject: Subject<LiveStreamStatus> = new BehaviorSubject<LiveStreamStatus>({ Live: false, Date: null });
    liveStreamStatus: Observable<LiveStreamStatus> = this.liveStreamStatusSubject.asObservable();

    private subject = webSocket(this.environmentService.websocketCorridor);

    constructor(private environmentService: EnvironmentService) {
        if (!environment.webSocketEnabled) {
            return;
        }
        this.subject.asObservable().subscribe(dataFromServer => {
            let data: any = dataFromServer;
            if (data.Path == 'greenlight') {
                this.greenlightPointsSubject.next(data.Data);
            }
            if (data.Path == 'livestream-v2') {
                this.liveStreamStatusSubject.next(data.Data);
            }
            if (data.Path == 'greenlight-shop-limit') {
                this.greenlightPointsLimitSubject.next(data.Data);
            }
        });

        // 2 minute ping to keep websocket open
        this.pingInterval = interval(480000)
            .subscribe((val) => {
                this.subject.next({ 'path': 'ping', 'action': 'ping', 'platform': 'Web' });
            });
    }

    public join(path: string) {
        if (!environment.webSocketEnabled) {
            return;
        }
        if (this.pathsJoined.indexOf(path) === -1) {
            this.subject.next({ 'path': path, 'action': 'join', 'platform': 'Web' });
            this.pathsJoined.push(path);
        }
    }

    public leave(path: string) {
        if (!environment.webSocketEnabled) {
            return;
        }
        this.subject.next({ 'path': path, 'action': 'leave', 'platform': 'Web' });

        const index = this.pathsJoined.indexOf(path, 0);
        if (index > -1) {
            this.pathsJoined.splice(index, 1);
        }
    }

    public closeConnection() {
        if (this.subject) {
            this.subject.complete();
            this.subject = null;
            this.pingInterval.unsubscribe();
        }
    }
}
