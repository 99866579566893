import { Component, Input, OnInit } from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { environment } from 'src/environments/environment';
import { EmojiCustom } from '../../models/emoji-custom';

@Component({
    selector: 'app-chat-read',
    templateUrl: './chat-read.component.html',
    styleUrls: ['./chat-read.component.css']
})
export class ChatReadComponent implements OnInit {

    badges: any[] = [];
    deleted: boolean;

    @Input() comment: any;

    constructor(private chatService: ChatService) {
    }

    ngOnInit() {

        if (this.comment.badges) {
            for (let badge of this.comment.badges) {
                if (badge == '10-year') {
                    this.badges.push({
                        icon: badge,
                        text: 'Subscribed during the 10 year anniversary'
                    });
                } else if (badge == 'beta') {
                    this.badges.push({
                        icon: badge,
                        text: 'Subscribed during Beta'
                    });
                } else if (badge == 'crewcuts') {
                    this.badges.push({
                        icon: badge,
                        text: 'Helped Greenlight Crew Cuts'
                    });
                } else if (badge == 'ff') {
                    this.badges.push({
                        icon: badge,
                        text: 'Helped Greenlight Functional Filmmaking'
                    });
                } else if (badge == 'lz') {
                    this.badges.push({
                        icon: badge,
                        text: 'Helped Greenlight Local Zero'
                    });
                } else if (badge == 'soad') {
                    this.badges.push({
                        icon: badge,
                        text: 'Helped Greenlight Son of a Dungeon'
                    });
                } else if (badge == 'mod') {
                    this.badges.push({
                        icon: badge,
                        text: 'Corridor Moderator'
                    });
                } else {
                    this.badges.push({
                        icon: badge,
                        text: badge
                    });
                }
            }
        }
    }

    report() {
        if (confirm('Are you sure you want to report this chat message?')) {
            this.chatService.reportMessage('live', this.comment.id).subscribe(
                res => {
                    this.deleted = true;
                }
            );
        }
    }

    logoUrl() {
        return environment.assets.logo;
    }
}
