import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ChannelsService } from 'src/app/services/channels.service';
import { AccountService } from 'src/app/services/account.service';
import { ChannelResponse, HeroResponse } from '../../models';
import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { ScreenSizeEnum } from '../../models/screen-size.enum';
import { HeroService } from 'src/app/services/hero.service';
import { WebSocketService } from '../../services/websocket.service';
import { LiveService } from '../../services/live.service';
import { copyObj } from '../../modules/helper-functions';
import { UiService } from 'src/app/services/ui.service';
import { WatchHistoryService } from '../../services/watch-history.service';
import { UserInfoService } from '../../services/user-info.service';

@Component({
    selector: 'app-channel-slider-v2',
    templateUrl: './channel-slider-v2.component.html',
    styleUrls: ['./channel-slider-v2.component.scss']
})
export class ChannelSliderV2Component implements OnInit, OnDestroy {

    channels: ChannelResponse[];
    @Input() type: string;
    @Input() header: string;

    hero: HeroResponse;
    isMobileView = false;
    destroy = new Subject<void>();
    @Input() heroResponse: HeroResponse;

    constructor(
        private ui: UiService,
        private authenticationService: AccountService,
        private channelsService: ChannelsService,
        private heroService: HeroService,
        private websocketService: WebSocketService,
        private liveService: LiveService,
        private watchHistoryService: WatchHistoryService,
        private userInfo: UserInfoService) {
        this.ui.breakpointObservable
            .pipe(
                takeUntil(this.destroy)
            )
            .subscribe((screenSize: ScreenSizeEnum) => {
                this.isMobileView = screenSize < ScreenSizeEnum.Large;
            });
    }

    async ngOnInit() {
        this.ui.startLoading();
        if (this.type === 'standard') {

            let hero$ = this.heroService.getHeroV5Anon();
            if (this.isAuthenticated()) {
                hero$ = this.heroService.getHeroV5Auth();
            }

            hero$.subscribe(res => {
                this.heroResponse = res;
            });

            const [channels] = await Promise.all([
                lastValueFrom(this.channelsService.getChannels(false, !this.userInfo.hasSubscription)),
                this.watchHistoryService.refreshWatchHistory()
            ]).finally(() => {
                this.ui.stopLoading();
            });

            this.channelsService.refreshUserChannels();

            this.channels = channels;

            this.websocketService.liveStreamStatus
                .pipe(takeUntil(this.destroy))
                .subscribe(async x => {
                    const index = this.channels.findIndex(x => x.slug === 'latest-uploads' || x.id === 23);
                    if (x.Live && this.channels[index] && this.channels[index].media[0].id !== -71) {
                        this.channels[index].media.unshift(await lastValueFrom(this.liveService.getLiveMedia()));
                        this.channels[index] = copyObj(this.channels[index]);
                        return;
                    }

                    if (!x.Live && this.channels[index] && this.channels[index].media[0].id === -71) {
                        this.channels[index].media.splice(0, 1);
                        this.channels[index] = copyObj(this.channels[index]);

                    }
                });


        } else if (this.type === 'shows') {
            this.channelsService.getChannelsShows()
                .subscribe({
                    next: res => {
                        this.channels = res;
                        if (this.channels[0].type === 'season-banner') {
                            this.header = undefined;
                        }
                    },
                    error: error => {
                        this.authenticationService.checkUnauthorized(error);
                    }
                }).add(() => {
                this.ui.stopLoading();
            });
        }
    }

    ngOnDestroy() {
        this.destroy.next();
        this.destroy.complete();
    }

    isAuthenticated() {
        return this.authenticationService.isAuthenticated();
    }
}
