import { Component, OnInit } from '@angular/core';
import { BadgeService } from 'src/app/services/badge.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AppComponent } from 'src/app/app.component';
import { ActivatedRoute, Router } from '@angular/router';
import { UserBadge } from '../../../models';
import { AuthenticationOverride } from '../../../modules/authentication-override';
import { StorageManagerService } from '../../../services/storage-manager.service';
import { AccountService } from '../../../services/account.service';
import { RemoteConfigService } from '../../../services/remote-config.service';
import { EnvironmentService } from '../../../services/environment.service';

@Component({
    selector: 'app-badges',
    templateUrl: './badges.component.html',
    styleUrls: ['./badges.component.scss']
})
export class BadgesComponent extends AuthenticationOverride implements OnInit {

    message: string;
    unorderedBadges: UserBadge[];
    orderedBadges: UserBadge[];

    constructor(
        private app: AppComponent,
        private badgeService: BadgeService,
        public router: Router,
        public config: RemoteConfigService,
        public env: EnvironmentService,
        storage: StorageManagerService,
        account: AccountService,
        route: ActivatedRoute) {
        super(route, storage, router, account)
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.badgeService.getBadges().subscribe(res => {
            this.unorderedBadges = res.filter(f => !f.order || f.order === null);
            this.orderedBadges = res.filter(f => f.order && f.order !== null);
        });
    }

    drop(event: CdkDragDrop<UserBadge[]>) {
        this.message = null;
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            if (event.container.id === 'cdk-drop-list-1' && event.container.data.length === 3) {
                this.message = 'Only three badges allowed at a time.';
                return;
            }
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }
    }

    submit() {
        this.app.startLoading();
        this.badgeService.updateBadges(this.orderedBadges?.map(m => m.type)).subscribe(res => {
			console.log(this.orderedBadges);
            this.app.showSimpleAlert({
                title: 'Success',
                body: 'Your badges were successfully changed.'
            });
            this.router.navigate(['/account']);
        });
    }
}
