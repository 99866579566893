import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { StreamReport, VideoResponse } from '../models';
import { EnvironmentService } from './environment.service';
import { VideoReportModel } from '../models/video-report-model';

@Injectable({
    providedIn: 'root'
})
export class VideoService {

    private liveCount: number = 0;

    constructor(
        private envService: EnvironmentService,
        private http: HttpClient) {
    }

    getVideo(mediaId: number, platform: string): Observable<VideoResponse> {
        return this.http
            .get<VideoResponse>(this.envService.contentHost + '/v5/video/' + mediaId + '?platform=' + platform)
            .pipe(catchError(error => {
                if (!environment.production) {
                    console.error('VideoService::Error::GET', error);
                }
                return throwError(error);
            }));
    }

    getFreeVideo(mediaId: number, platform: string): Observable<VideoResponse> {
        console.log('Platform: ' + platform);
        return this.http
            .get<VideoResponse>(this.envService.contentHost + '/v5/video/' + mediaId + '/free?platform=' + platform)
            .pipe(catchError(error => {
                if (!environment.production) {
                    console.error('VideoService::Error::GET', error);
                }
                return throwError(error);
            }));
    }

    reportStreamProgress(uid: string, videoId: number, streamReport: StreamReport): Observable<any> {
        return this.http
            .post<any>(this.envService.userHost + '/v1/videoReport/' + uid + '?platform=Web&videoId=' + videoId,
                streamReport)
            .pipe(catchError(error => {
                if (!environment.production) {
                    console.error('VideoService-Reporting::Error::POST', error);
                }
                return throwError(error);
            }));
    }

    reportMedia(mediaId: number, report: VideoReportModel): Observable<any> {
        return this.http
            .post<any>(this.envService.contentHost + '/v5/video/' + mediaId + '/report', report);
    }

    getReportReasons(mediaId: number): Observable<{ [key: string]: string; }> {
        return this.http
            .get<{ [key: string]: string; }>(this.envService.contentHost + '/v5/video/' + mediaId + '/report');
    }

    setLiveCount(liveCount: number) {
        this.liveCount = liveCount;
    }

    getLiveCount(): number {
        return this.liveCount;
    }
}
