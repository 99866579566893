<div class="main main-full main-responsive">
    <app-title [title]="'account-badges-choose' | translate"></app-title>
    <div cdkDropListGroup class="mb-3">
        <div>
            <h5>{{ "account-badges-available" | translate}}</h5>
            <div id="dropZoneStart">
                <div *ngIf="unorderedBadges && unorderedBadges.length === 0">
                    {{ "account-badges-none-available" | translate}}
                </div>
                <div class="badge-list" cdkDropList [cdkDropListData]="unorderedBadges"
                    cdkDropListOrientation="horizontal" #listOne="cdkDropList" [cdkDropListConnectedTo]="[listTwo]"
                    (cdkDropListDropped)="drop($event)">
                    <div class="badge-box" cdkDrag *ngFor='let badge of unorderedBadges'>
                        <img class="chat-badge-large center" alt="{{badge.type}}" title="{{badge.type}}"
                            src="https://cdn.watchcorridor.com/badges/{{badge.type}}.png" *ngIf="config.remoteConfig.comments_version == '1'" />
                        <img class="chat-badge-large center" alt="{{badge.type}}" title="{{badge.type}}"
                             [src]="env.imageHost +  '/badges/' + badge.type + '.png'" *ngIf="config.remoteConfig.comments_version == '2'" />
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-4">
            <h5>{{ "account-badges-picked" | translate}}</h5>
            <small *ngIf="config.remoteConfig.livestream_enabled">{{ "account-badges-picked-text" | translate}}</small>
            <span class="text-danger d-block">{{message}}</span>
            <div id="dropZoneEnd">
                <div *ngIf="orderedBadges && orderedBadges.length === 0">
                    {{ "account-badges-none-picked" | translate}}
                </div>
                <div class="badge-list" cdkDropList [cdkDropListData]="orderedBadges"
                    cdkDropListOrientation="horizontal" #listTwo="cdkDropList" [cdkDropListConnectedTo]="[listOne]"
                    (cdkDropListDropped)="drop($event)">
                    <div class="badge-box" cdkDrag *ngFor='let badge of orderedBadges'>
                        <img class="chat-badge-large center" alt="{{badge.type}}" title="{{badge.type}}"
                             src="https://cdn.watchcorridor.com/badges/{{badge.type}}.png" *ngIf="config.remoteConfig.comments_version == '1'" />
                        <img class="chat-badge-large center" alt="{{badge.type}}" title="{{badge.type}}"
                             [src]="env.imageHost +  '/badges/' + badge.type + '.png'" *ngIf="config.remoteConfig.comments_version == '2'" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="form-group">
        <button type="submit" class="btn btn-outline-primary btn-slim" (click)="submit()">{{ "account-badges-update" |
            translate}}</button>
    </div>

    <a (click)="back('/account')" class="btn btn-outline-light btn-slim mt-3">{{ "account-back" | translate}}</a>
</div>
