<div class="row mt-1 pr-1 read-row" *ngIf="!deleted">
    <div class="col-10 col-sm-11">
        <span class="mb-0">
            <img *ngIf="comment.official" class="chat-badge" alt="Corridor Official" title="Corridor Official"
                src="{{logoUrl()}}" />
            <ng-container *ngFor='let badge of badges'>
                <img class="chat-badge ml-1" alt="{{badge.text}}" title="{{badge.text}}"
                    src="https://cdn.watchcorridor.com/badges/{{badge.icon}}.png" />
            </ng-container>

            <span class="text-crew" *ngIf="comment.official == true">
                {{comment.name}}:
            </span>
            <span *ngIf="comment.official == false">
            {{comment.name}}:
        </span>
        </span>
        <span class="mb-0 word-wrap" [innerHtml]="comment.message | chat : 0 : true | async"></span>
    </div>
    <div class="col-2 col-sm-1">
        <div class="chat-report" id="report-container">
            <span class="float-right material-symbols-outlined pointer" title="REPORT" (click)="report()">flag</span>
        </div>
    </div>
</div>
