import { Pipe, PipeTransform } from '@angular/core';
import { EmojiCustom } from '../models/emoji-custom';
import { ChatService } from '../services/chat.service';
import { map, Observable, of } from 'rxjs';

@Pipe({
    name: 'chat'
})
export class ChatPipe implements PipeTransform {

    customEmojis: EmojiCustom[];

    mentionPattern = new RegExp('(@[a-zA-Z0-9]*)', 'gm');
    emojiPattern = new RegExp(':(?=.*?[A-Za-z])([a-zA-Z_0-9-]*):', 'gm');
    timestampPattern = new RegExp('(([0-9]*):)?([0-9]*):([0-9]*)', 'gm');

    constructor(private service: ChatService) {
    }

    transform(value: string, mediaId: number, isChat: boolean): Observable<string> {

        if (!value) {
            return of(value);
        }

        return this.service.getEmojis().pipe(
            map(x => {

                value = value.replace(this.mentionPattern, '<span class="text-primary">$1</span>');

                this.customEmojis = x;
                let regex;
                while (regex = this.emojiPattern.exec(value)) {
                    const customEmoji = this.findEmoji(regex[1]);
                    if (customEmoji) {
                        value = value.replace(regex[0], `<img class="emoji" src="${this.findEmoji(regex[1])?.imageUrl}" />`)
                    }
                }

                if (!isChat) {
                    value = this.applyTimestamps(value, mediaId)
                }

                return value;
            })
        )
    }

    findEmoji(keyword: string) {
        return this.customEmojis.find(x => x.keywords[0].includes(keyword)) ??
            this.customEmojis.find(x => x.keywords[0].includes(keyword.replace('-', '_')))
    }

    applyTimestamps(value: string, mediaId: number): string {
        let match;
        let message = value;
        do {
            match = this.timestampPattern.exec(message);
            if (match && match[0] !== '' && match[0] !== ':') {

                let timeS = 0;

                if (match[2] && match[2] !== '') {
                    timeS += +match[2] * 60 * 60;
                }
                if (match[3] && match[3] !== '') {
                    timeS += +match[3] * 60;
                }
                if (match[4] && match[4] !== '') {
                    timeS += +match[4];
                }

                value = message.replace(match[0], `<a href="/video/${mediaId}?t=${timeS}s" onclick="timestampClicked(${mediaId},${timeS});return false;" class="yellow">${match[0]}</span></a>`);
            }
        } while (match);
        return value;
    }

}
