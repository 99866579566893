<!-- Footer -->
<section id="footer" class="pt-2">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 company-links">
                <a href="{{config.remoteConfig.instagram_link}}" target="_blank"
                   *ngIf="config.remoteConfig.instagram_link">Instagram</a>
                <a routerLink="/home/feedback" target="_blank">{{ "footer-contact" | translate }}</a>
            </div>
        </div>
        <div class="row">
            <div class="col-2 text-center text-footer"></div>
            <div class="col-8 text-center text-footer">
                <p class="mb-2">{{config.remoteConfig.copyright_text}}</p>
            </div>
            <div class="col-2 text-center text-footer">
                <p class="mb-2 text-muted small text-right">v{{version()}}</p>
            </div>
        </div>
    </div>
</section>
<!-- Footer End -->
