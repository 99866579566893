import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { RateMediaService } from 'src/app/services/rate-media.service';
import { AccountService } from '../../services/account.service';

@Component({
    selector: 'app-rate',
    templateUrl: './rate.component.html',
    styleUrls: ['./rate.component.scss']
})
export class RateComponent implements OnInit, OnChanges {

    @Input() mediaId: string;

    likes: number = 0;
    dislikes: number = 0;

    liked: boolean = false;
    disliked: boolean = false;

    constructor(
        private account: AccountService,
        private rateMediaServices: RateMediaService) {
    }

    ngOnInit(): void {
        this.init();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.init();
    }

    init() {
        this.liked = false;
        this.disliked = false;
        this.account.updateToken().then(
            () => {
                if (this.account.isAuthenticated()) {
                    this.rateMediaServices.getRating(this.mediaId).subscribe(res => {
                        this.likes = res.likes;
                        this.dislikes = res.dislikes;
                        if (res.liked === true) {
                            this.liked = true;
                        } else if (res.liked === false) {
                            this.disliked = true;
                        }
                    });
                } else {
                    this.rateMediaServices.getRatingAnonymous(this.mediaId).subscribe(res => {
                        this.likes = res.likes;
                        this.dislikes = res.dislikes;
                        if (res.liked === true) {
                            this.liked = true;
                        } else if (res.liked === false) {
                            this.disliked = true;
                        }
                    });
                }
            },
            () => {
            });
    }

    like() {
        if (this.account.isAuthenticated()) {
            if (this.disliked) {
                this.dislikes -= 1;
            }
            if (this.liked) {
                this.liked = false;
                this.likes -= 1;
                this.rateMediaServices.setRating(this.mediaId, null).subscribe(res => {
                });
            } else {
                this.liked = true;
                this.disliked = false;
                this.likes += 1;
                this.rateMediaServices.setRating(this.mediaId, true).subscribe(res => {
                });
            }
        }
    }

    dislike() {
        if (this.account.isAuthenticated()) {
            if (this.liked) {
                this.likes -= 1;
            }
            if (this.disliked) {
                this.disliked = false;
                this.dislikes -= 1;
                this.rateMediaServices.setRating(this.mediaId, null).subscribe(res => {
                });
            } else {
                this.disliked = true;
                this.liked = false;
                this.dislikes += 1;
                this.rateMediaServices.setRating(this.mediaId, false).subscribe(res => {
                });
            }
        }
    }
}
