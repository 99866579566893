<section class="main main-full main-responsive">
    <app-title [title]="'forgotten-password' | translate"
               [subtitle]="'forgot-password-type-email' | translate"
    ></app-title>
    <form (ngSubmit)="resetPassword()">
        <input [(ngModel)]="email" type="email" class="form-control uikit-input" name="email" autofocus
               placeholder="Email" tabindex="1"/>
        <div class="form-group block error" id="error-container" *ngIf="errorMessage">
            {{errorMessage}}
        </div>
        <button [disabled]="!email" type="submit" tabindex="3" id="submit-button" class="btn btn-lg btn-primary btn-block">
            {{ 'reset password' | uppercase }}
        </button>
    </form>
    <div id="link-text-container">
        <p class="mt-4">
            {{ "payment-type-access-register-already-have-account" | translate }}
            <button (click)="changePage('login')" class="btn btn-link p-0">{{ "payment-type-access-register-login-here" | translate }}</button>
        </p>
        <p>
            {{ "login-no-account" | translate }}
            <button (click)="changePage('signup')" class="btn btn-link p-0">{{ "sign-up" | translate}}</button>
        </p>
    </div>
</section>
