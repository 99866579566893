export const environment = {
    production: true,
    forceMaintenance: false,
    webSocketEnabled: false,
    googleAnalyticsEnabled: false,
    googleAnalyticsId: 'UA-000000000-1',
    projectsEnabled: true,
    name: 'Fairway TV',
    domain: '.fairway.tv',
    project: 'fairway-tv',
    socketUrl: 'https://socket.fairway.tv',
    cookiebotId: '0965b578-cd84-4916-acad-eb0a9e9e227c',
    recaptchaKey: '6LcJ450pAAAAAJTXIQuRKi6AusX9tJx7RtD0lO4Y',
    recaptchaDebug: '43FB4F6F-D8FD-4FEB-9F39-CBAFC566814B',
    assets: {
        logo: 'https://cdn.fairway.tv/assets/logo_short.svg',
    },
    google: {
        client_id: '385875004089-a94hn3lt4ai7ilv4tpt9dh8u23pi24ml.apps.googleusercontent.com',
        project_id: 'watch-fairway',
        auth_uri: 'https://accounts.google.com/o/oauth2/auth',
        token_uri: 'https://oauth2.googleapis.com/token',
        auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
        client_secret: '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'
    },
    apple: {
        client_id: '',
        scope: '',
        state: '',
        nonce: ''
    },
    firebaseConfig: {
        apiKey: 'AIzaSyA4RUKeHNq9vtsgPxBBIMz4ErY1qyP8D80',
        authDomain: 'fairway-tv.firebaseapp.com',
        projectId: 'fairway-tv',
        storageBucket: 'fairway-tv.appspot.com',
        messagingSenderId: '308838222053',
        appId: '1:308838222053:web:b695a425ae3d0d8daf6c0c',
        measurementId: 'G-RVN0880P0G'
    },
    stripe: {
        pk_test: 'pk_test_51OqZrEJkZDtwEUF9JxmXP5ZGp9BLIL6qAeIn8qGSJtk24LVjO80yMp3ZPatRCUPgS0HtTwXXmIGne4WDkxBUII6I00g4S6GvCo',
        pk_live: 'pk_live_51OqZrEJkZDtwEUF9qk3hwmtCEVeOIocT1prkod5WfUL13mMdpijTA4GO71RSX8z5kgMuSsicYRsSuHe0yTJ2G29X00c88OgSQZ'
    }
};
