import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EnvironmentService } from './environment.service';
import { LiveVideoResponse } from '../models';

@Injectable({
    providedIn: 'root'
})
export class LiveService {


    constructor(
        private envService: EnvironmentService,
        private http: HttpClient) {
    }

    getLiveVideo(): Observable<LiveVideoResponse> {
        return this.http
            .get<any>(this.envService.contentHost + '/v1/live')
            .pipe(catchError(error => {
                if (!environment.production)
                    console.error('LiveService::Error::GET', error);
                return throwError(error);
            }));
    }

    getLiveMedia() {
        return this.http
            .get<any>(this.envService.contentHost + '/v1/live/media')
            .pipe(catchError(error => {
                if (!environment.production)
                    console.error('LiveService::Error::GET', error);
                return throwError(error);
            }));
    }
}
