<ng-container *ngIf="config.remoteConfig.comments_enabled">

    <div>
        <h3 class="white comments-title">Comments</h3>

        <div *ngIf="authed" class="comment-sort-buttons">
            <button [class.active]="topComments$ | async" (click)="sortByTop()">Top</button>
            <button [class.active]="!(topComments$ | async)" (click)="sortByNewest()">Newest</button>
        </div>
    </div>

    <hr>

    <div *ngIf="!authed">
        <p>Sign in for Comments</p>
    </div>

    <div id="comments" *ngIf="authed">
        <app-comment-write [mediaId]="mediaId"></app-comment-write>
        <div class="mt-3">
            <div *ngFor="let comment of comments">
                <app-comment-read [comment]="comment" [mediaId]="mediaId"></app-comment-read>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-sm">
                <p *ngIf='!comments'>
                    Loading comments...
                </p>
                <p *ngIf='comments && noComments'>
                    No comments yet!
                </p>
            </div>
        </div>
        <div #bottomOfComments></div>
        <div *ngIf="lastComment" class="position-relative div-loader">
        </div>
    </div>
</ng-container>
