<div class="container-fluid pb-4">
    <div id="chat-container" class="chat-container mb-3 scroll-colour mt-3 mt-lg-0" [ngClass]="video ? '' : 'chat-full'"
        [scrollTop]="scrollToBottom()" #scrollMe [style.max-height]="getVideoHeight()">
        <p>Welcome to the live chat!</p>
        <div *ngFor="let comment of comments">
            <app-chat-read [comment]='comment'></app-chat-read>
        </div>
    </div>
    <div class="row d-none d-lg-block" [ngClass]="video ? 'chat-box' : ''">
        <div class="col-12 mt-5">
            <input #comment (keyup.enter)="send()" type="text" name="comment" [(ngModel)]="chat.message"
                #message="ngModel" class="form-control text-input-dark inline-block emoji-padding"
                placeholder="Something friendly to say?" tabindex="1" autocomplete="off">
            <img class="floating-image-4 pointer" (click)="emoji()" src="/assets/smiley.png" height="20px" />
            <img class="floating-image-5 pointer" (click)="send()" src="/assets/send.png" height="20px" />

            <emoji-mart *ngIf="customEmojis" (emojiClick)="addEmoji($event)" [darkMode]="true" [custom]="customEmojis" [include]="includes"
                [i18n]="translations" [style]="{ position: 'absolute', right: '50px', marginTop: '-510px' }"
                [hidden]="hideEmoji">
            </emoji-mart>
        </div>
    </div>
    <div class="row d-none d-lg-block" [ngClass]="video ? 'stats-video' : 'stats-chat'">
        <div class="w-100 pl-2 pr-2">

            <div class="d-inline pl-3">
                <label class="form-switch">
                    <span class="switch-text">Autoscroll</span>
                    <input type="checkbox" [(ngModel)]="autoscroll">
                    <i></i>
                </label>
            </div>

            <div class="d-inline pl-3" *ngIf="video">
                <a href="/live-chat" target="_blank">Popout chat</a>
            </div>
        </div>
    </div>

    <div class="row d-block d-lg-none">
        <div class="col-12 mt-2">
            <input #comment (keyup.enter)="send()" type="text" name="comment" [(ngModel)]="chat.message"
                #message="ngModel" class="form-control text-input-dark inline-block emoji-padding"
                placeholder="Something friendly to say?" tabindex="1" autocomplete="off">
            <img class="floating-image-4 pointer" (click)="emoji()" src="/assets/smiley.png" height="20px" />
            <img [disableControl]="!allowedToSend" class="floating-image-5 pointer" (click)="send()"
                src="/assets/send.png" height="20px" />

            <emoji-mart *ngIf="customEmojis" (emojiClick)="addEmoji($event)" [darkMode]="true" [custom]="customEmojis" [include]="includes"
                [i18n]="translations" [style]="{ position: 'absolute', right: '50px', marginTop: '-475px' }"
                [hidden]="hideEmoji">
            </emoji-mart>
        </div>
    </div>
    <div class="row d-block d-lg-none">
        <div class="w-100 pl-2 pr-2 mt-2">
            <div class="d-inline">
                <img class="pb-1" width="18px" src="{{config.remoteConfig.viewer_icon}}" />
                {{getLiveCount()}}
            </div>

            <div class="d-inline pl-3">
                <label class="form-switch">
                    <span class="switch-text">Autoscroll</span>
                    <input type="checkbox" [(ngModel)]="autoscroll">
                    <i></i>
                </label>
            </div>

            <div class="d-inline pl-3" *ngIf="video">
                <a href="/live-chat" target="_blank">Popout chat</a>
            </div>
        </div>
    </div>
</div>
