import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Subject} from 'rxjs';
import {RemoteConfigService} from '../../services/remote-config.service';
import {environment} from 'src/environments/environment';

@Component({
    selector: 'app-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit, OnDestroy {

    mediaId: number;
    autoPlay = true;
    safariHls = false;

    destroy$: Subject<void> = new Subject<void>();
    showReportReasons: boolean = false;
    videoLoaded: boolean = false;

    constructor(
        router: Router,
        private route: ActivatedRoute,
        private titleService: Title,
        public config: RemoteConfigService) {

        this.mediaId = +this.route.snapshot.paramMap.get('id');

        const autoPlayStorage = localStorage.getItem('autoplay');
        if (autoPlayStorage) {
            this.autoPlay = JSON.parse(autoPlayStorage);
        }

        if (!this.config.remoteConfig.up_next_enabled) {
            this.autoPlay = true;
        }

        router.routeReuseStrategy.shouldReuseRoute = () => {
            return false;
        };
    }


    ngOnDestroy() {
        this.titleService.setTitle(environment.name);
    }

    ngOnInit() {
    }

    autoPlayToggled(autoPlay: boolean) {
        this.autoPlay = autoPlay;
    }
}
