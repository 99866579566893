import { HttpInterceptor } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { StorageManagerService } from '../storage-manager.service';
import { RemoteConfigService } from '../remote-config.service';
import { AccountService } from '../account.service';
import { switchMap, take, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

    constructor(private injector: Injector,
                private config: RemoteConfigService) {
    }

    intercept(req, next) {

        if (req.url.includes('remoteconfig')) {
            return next.handle(req);
        }

        let addAuth = false;
        const url = req.url;
        if (
            url.includes('v1/avatar') ||
            url.includes('v1/badges') ||
            url.includes('v1/chat') ||
            url.includes('v1/changePassword') ||
            url.includes('v1/comments') ||
            url.includes('v2/continueWatching') ||
            url.includes('v3/continueWatching') ||
            url.includes('v3/userchannels') ||
            url.includes('v4/userchannels') ||
            url.includes('v1/displayName') ||
            url.includes('v1/emailPreferences') ||
            url.includes('v1/legaldocuments') ||
            url.includes('v1/key') ||
            url.includes('v1/notification') ||
            url.includes('v2/notification') ||
            url.includes('v2/pushNotification') ||
            url.includes('v2/pushnotification') ||
            url.includes('v1/rateMedia') ||
            url.includes('v1/recommendations') ||
            url.includes('v2/recommendations') ||
            url.includes('v3/recommendations') ||
            url.includes('v1/payPalSubscription') ||
            url.includes('v2/payPalSubscription') ||
            url.includes('v1/purchaseHistory') ||
            url.includes('v1/stripePurchase') ||
            url.includes('v1/videoReport') ||
            url.includes('v1/patreon') ||
            url.includes('v1/points') ||
            url.includes('v1/email') ||
            url.includes('v2/playlist') ||
            url.includes('v3/playlist') ||
            url.includes('ugw') ||
            url.includes('v1/promocode') ||
            url.includes('v1/subscription') ||
            url.includes('v2/subscription') ||
            url.includes('v3/subscription') ||
            url.includes('v4/subscription') ||
            url.includes('v5/subscription') ||
            url.includes('v6/subscription') ||
            url.includes('v1/stripe') ||
            url.includes('v2/stripe') ||
            url.includes('v1/user') ||
            url.includes('v1/live') ||
            url.includes('v1/smartlogin') ||
            url.includes('v2/smartlogin') ||
            url.includes('v1/userdevice') ||
            url.includes('v1/voucher') ||
            url.includes('v1/watchHistory') ||
            url.includes('v1/smush') ||
            url.includes('v2/video') ||
            url.includes('v3/video') ||
            url.includes('v4/video') ||
            url.includes('v5/video') ||
            (url.includes('v2/projects') && req.method == 'POST') ||
            (url.includes('v3/projects') && req.method == 'POST') ||
            (url.includes('v5/projects') && req.method == 'POST') ||
            url.includes('/v2/account/change/password') ||
            url.includes('/v2/account/change/email') ||
            url.includes('/v2/subtitleoptions') ||
            url.includes('/v5/hero/auth') ||
            url.includes('/v1/preferences') ||
            url.includes('/v3/comments')
        ) {
            addAuth = true;
        }

        if (addAuth) {
            let storageManager = this.injector.get(StorageManagerService);
            let config = this.injector.get(RemoteConfigService);

            let accessToken = storageManager.getOverrideToken();
            if (!accessToken || accessToken === 'null') {

                if (config.remoteConfig.login_version == 1){
                    accessToken = storageManager.getToken();
                }

                if (config.remoteConfig.login_version == 2) {
                    let account = this.injector.get(AccountService);
                    return account.updateTokenV2()
                        .pipe(
                            take(1),
                            switchMap(token => {
                                const tokenizedReq = req.clone({
                                    setHeaders: {
                                        Authorization: `bearer ${token}`
                                    }
                                });
                                return next.handle(tokenizedReq);
                            }),
                            catchError(err => {
                                console.log('interceptor', err);
                                if (err && err.status === 401) {
                                    const dontLogOut = ['change/password'];
                                    if (!dontLogOut.some(x => url.includes(x))) {
                                        account.logoutUser();
                                    }
                                }
                                return throwError(err);
                            })
                        )
                }

            }

            let tokenizedReq = req.clone({
                setHeaders: {
                    Authorization: `bearer ${accessToken}`
                }
            });
            return next.handle(tokenizedReq);
        }
        return next.handle(req);
    }
}
