import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, map, Observable, Subject, takeUntil } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Utils } from '../../services/utils.service';
import { MetadataService } from '../../services/metadata.service';
import { MetadataDataResponseV2 } from '../../models';
import { Title } from '@angular/platform-browser';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
    selector: 'app-video-description',
    templateUrl: './video-description.component.html',
    styleUrls: ['./video-description.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoDescriptionComponent implements OnInit {

    @Input() mediaId: number;
    @Input() videoLoaded: boolean = false;
    @Input() safariHls: boolean = false;

    @Output() reportClicked = new EventEmitter<void>();

    metadata$: Observable<MetadataDataResponseV2>;
    showShort$ = new BehaviorSubject<boolean>(true);

    destroy$: Subject<void> = new Subject<void>();

    constructor(private metadataService: MetadataService,
                private titleService: Title,
                private router: Router) {
    }

    ngOnInit(): void {
        this.metadata$ = this.metadataService.getMetadata(this.mediaId)
            .pipe(
                map(res => {
                    this.titleService.setTitle(res.metadata.title + ' - ' + environment.name);
                    res.metadata.datePublished = Utils.getTime(res.metadata.datePublished);
                    return res.metadata;
                }),
                catchError(err => {
                    if (err.status === 404) {
                        this.router.navigate(['/not-found']);
                    } else {
                        this.router.navigate(['/']);
                    }
                    throw err;
                })
            );
    }

    toggleDescription() {
        this.showShort$.next(!this.showShort$.value);
    }
}
