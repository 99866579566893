<div *ngIf="!endOfVideo" class="shaka-container" [class.player-pip]="pip" [ngClass]="subtitleClass$ | async">
    <div #videoUI id="videoUI" data-shaka-player-container data-shaka-player-cast-receiver-id="F70D4520" data-shaka-player-cast-android-receiver-compatible="true">
        <video #videoPlayer data-shaka-player id="video" style="width:100%" autoplay [poster]="poster"></video>
        <button *ngIf="shown()" (click)="skipBackward()" class="shaka-overlay-button shaka-skip-backward"
                aria-label="Skip Backward"></button>
        <button *ngIf="shown()" (click)="skipForward()" class="shaka-overlay-button shaka-skip-forward"
                aria-label="Skip Forward"></button>

        <button *ngIf="shown() && pip && !isFullScreen" (click)="closePip()" class="shaka-overlay-button shaka-skip-close"
                aria-label="Close"></button>
        <!--<div class="video-countdown" *ngIf="autoplayNextVideo == true && autoplay && timeLeft < 20">
            <div class="video-countdown-text">
                Next video in<br />
                <span class="video-countdown-time">{{timeLeft | number: '1.0-0'}}</span>
            </div>
        </div>-->
        <div id="seek-preview"></div>
    </div>
</div>

<div *ngIf="endOfVideo" class="up-next-container">
    <div>
        <div *ngIf="!cancelled && endOfVideoTime > -1">
            <h4 class="text-dull d-inline-block">Next video in&nbsp;&nbsp;
            </h4>
            <h3 class="d-inline-block">{{ endOfVideoTime }}</h3>
        </div>
        <div>
            <img class="my-2 up-next-video-img" src="{{endOfVideoImage}}" width="300px" alt="Next Video"/>
        </div>
        <div>
            <div>
                <button *ngIf="!cancelled && endOfVideoTime > -1" class="btn btn btn-outline-light mr-2 w-48 btn-up-next"
                        (click)="cancelNextVideoTimer()">CANCEL
                </button>
                <button class="btn btn btn-outline-light w-48 btn-up-next" [class.w-100]="cancelled"
                        (click)="playNextVideo()">PLAY
                </button>
            </div>
            <div class="mt-2">
                <button class="btn btn-outline-light w-100 btn-up-next" (click)="watchAgain()">WATCH AGAIN</button>
            </div>
        </div>
    </div>
</div>
