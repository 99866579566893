import { Component, OnInit } from '@angular/core';
import { EnvironmentService } from '../../services/environment.service';

@Component({
    selector: 'app-developer-page',
    templateUrl: './developer-page.component.html',
    styleUrls: ['./developer-page.component.scss']
})
export class DeveloperPageComponent implements OnInit {
    devStats: {title: string, stat: string}[] = [];

    constructor(private env: EnvironmentService) {
    }

    ngOnInit(): void {
        this.devStats.push({title: 'Current Environment', stat: this.env.env});
        this.devStats.push({title: 'Stripe PK', stat: this.env.stripePk});
        
        // Add new stats here

        this.devStats.sort((a, b) => (a.title > b.title) ? 1 : -1)
    }

}
