import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { lastValueFrom, map, Observable, of, shareReplay, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StorageManagerService } from './storage-manager.service';
import { Location } from '@angular/common';
import { EnvironmentService } from './environment.service';
import { Auth, getAuth, onAuthStateChanged } from '@angular/fire/auth';
import { EmailPreferences } from '../models';
import { DeleteRequest } from '../models/delete-request';
import { UserInfoService } from './user-info.service';
import { AuthResponseV2 } from '../models/auth-response-v2';
import { RemoteConfigService } from './remote-config.service';

@Injectable({
	providedIn: 'root'
})
export class AccountService {

    private _loggingOut = new Subject<void>();
    get loggingOut() {
        return this._loggingOut.asObservable();
    }

	public nextRoute: string | undefined = undefined;
	private _isAuthenticated = false;

	private originalLocation = '';
    private renewingSession = false;
    private isOverride = false;

	constructor(
		private afAuth: Auth,
		private envService: EnvironmentService,
		private http: HttpClient,
		private router: Router,
		private storageManager: StorageManagerService,
		private location: Location,
        private userInfo: UserInfoService,
        private config: RemoteConfigService) {

	}

    checkAuthenticated() {
        return new Promise<void>(resolve => {
            if (this.config.remoteConfig.login_version == 1) {
                this.afAuth.onIdTokenChanged(res => {
                    if (res) {
                        res.getIdToken().then(tokenData => {
                            this.storageManager.setToken(tokenData);
                            this.setAuthenticated(true);
                        });
                    }
                });
            }

            let accessToken = this.storageManager.getOverrideToken();
            if (!accessToken || accessToken === 'null') {
                accessToken = this.config.remoteConfig.login_version == 2 ? this.storageManager.getAuthTokenV2()?.token : this.storageManager.getToken();
            } else {
                this.isOverride = true;
            }
            this.setAuthenticated(!!accessToken && accessToken !== 'null' && accessToken.trim() !== '');
            resolve();
        });
    }

    registerV2(email: string, password: string, displayName: string): Observable<AuthResponseV2> {
        return this.http
            .post<AuthResponseV2>(`${this.envService.userHost}/v2/account/register?platform=web`, { displayName, email, password })
            .pipe(map(x => {
                this.storageManager.setAuthTokenV2(x);
                this.setAuthenticated(true);
                return x;
            }));
    }

    loginV2(email: string, password: string): Observable<AuthResponseV2> {
        return this.http.post<AuthResponseV2>(`${this.envService.userHost}/v2/account/login?platform=web`, { email, password })
            .pipe(map(x => {
                this.storageManager.setAuthTokenV2(x);
                this.setAuthenticated(true);
                return x;
            }));
    }

    loginGoogleV2(token: string, displayName: string) {
        return this.http.post<AuthResponseV2>(`${this.envService.userHost}/v2/account/login/google?platform=web`, { token, displayName })
            .pipe(map(x => {
                this.storageManager.setAuthTokenV2(x);
                this.setAuthenticated(true);
                return x;
            }));
    }

    loginAppleV2(token: string, displayName: string) {
        return this.http.post<AuthResponseV2>(`${this.envService.userHost}/v2/account/login/apple?platform=web`, { token, displayName })
            .pipe(map(x => {
                this.storageManager.setAuthTokenV2(x);
                this.setAuthenticated(true);
                return x;
            }));
    }

    renewV2(jwtSession: AuthResponseV2): Observable<AuthResponseV2> {
        this.renewingSession = true;
        return this.http.post<AuthResponseV2>(`${this.envService.userHost}/v2/account/renew?platform=web`, jwtSession)
            .pipe(
                map(x => {
                    this.storageManager.setAuthTokenV2(x);
                    this.renewingSession = false;
                    return x;
                }),
                catchError((e) => {
                    console.log(e);
                    this.storageManager.removeAuthTokenV2();
                    this.setAuthenticated(false);
                    throw e;
                }));
    }

    changeEmailV2(newEmail: string) {
        return this.http.post<AuthResponseV2>(`${this.envService.userHost}/v2/account/change/email?platform=web`, { newEmail })
    }

    changeEmailCompleteV2(code: string) {
        return this.http.post<AuthResponseV2>(`${this.envService.userHost}/v2/account/change/email/complete?platform=web`, { code })
    }

    forgottenPasswordSendV2(email: string) {
        return this.http.post(`${this.envService.userHost}/v2/account/forgotten-password/send?platform=web`, {email});
    }

    forgottenPasswordChangeV2(code: string, passwordNew: string, email: string) {
        return this.http.post(`${this.envService.userHost}/v2/account/forgotten-password/change?platform=web`, {code, passwordNew, email});
    }

    changePasswordV2(email: string, passwordOld: string, passwordNew: string) {
        return this.http.post(`${this.envService.userHost}/v2/account/change/password?platform=web`, {passwordOld, passwordNew, email});
    }

	public checkUnauthorized(error) {
		if (error instanceof HttpErrorResponse) {
			if (error.status === 401) {
				this.logoutUser();
			}
		}
	}

	public isAuthenticated(): boolean {
		return this._isAuthenticated;
	}

	public setAuthenticated(authenticated: boolean): void {
		this._isAuthenticated = authenticated;
	}

	public logoutUser() {
		this.storageManager.removeSubscription();
		this.storageManager.removeToken();
		this.storageManager.removeEmail();
        this.storageManager.removeAuthTokenV2();
        this.storageManager.removeAfterLoginUrl();
		this._loggingOut.next();
		this.setAuthenticated(false);
        if (this.config.remoteConfig.login_version == 1) {
            this.afAuth.signOut().then(() => {
                this.router.navigate(['/login']);
            });
        } else {
            this.router.navigate(['/login'])
        }
	}

	async updateSubscriptionStatus() {
		const res = await lastValueFrom(this.userInfo.getUserInfoQuery('subscription'));
		this.storageManager.setSubscription(res.hasSubscription);
	}

    checkIp(): Observable<any> {
        return this.http
            .get<any>(this.envService.userHost + '/v1/verifyAccount')
    }

	verifyAccount(token: string, displayName?: string): Observable<any> {
		return this.http
			.post<any>(this.envService.userHost + '/v1/verifyAccount', {
				authToken: token,
				displayName: displayName ?? null
			})
			.pipe(catchError(error => {
				if (!environment.production)
					console.error('AuthenticationService::Error::GET', error);
				return throwError(error);
			}));
	}

    verifyEmail(code: string): Observable<any> {
        return this.http
            .post<any>(this.envService.userHost + '/v1/emailverify', {
                token: code,
            });
    }

    requestDelete(reason: string): Observable<DeleteRequest> {
        return this.http.delete<DeleteRequest>(this.envService.userHost + '/v1/userinfo?reason=' + reason)
    }

	cancelDeleteRequest(userId: number): Observable<any> {
		return this.http
			.put<any>(this.envService.userHost + '/v1/userinfo/cancel-delete?userId=' + userId, {})
	}

	changeDisplayName(displayName: string) {
		return this.http.put<any>(this.envService.userHost + '/v1/displayName', { displayName: displayName })
	}

	changeEmail(email: string) {
		return this.http.put<any>(this.envService.userHost + '/v1/email', { email: email })
	}

	navigateBack() {
		this.router.navigateByUrl(this.originalLocation.length > 0 ? this.originalLocation : '/');
	}

	navigateToAccessPage(page) {
		this.originalLocation = this.location.path();
		if (this.originalLocation.startsWith('/signup')) {
			this.originalLocation = '/';
		}
		this.router.navigate([page]);
	}

	submitEmailPreferences(preferences: EmailPreferences): Observable<any> {
		return this.http.post<any>(this.envService.userHost + '/v1/emailPreferences', preferences)
	}

	getEmailPreferences(): Observable<EmailPreferences> {
		return this.http.get<EmailPreferences>(this.envService.userHost + '/v1/emailPreferences')
	}

    decodeJwt(token: string): any {
        const base64Url = token.split('.')[1]; // Get the payload part
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'); // Convert to regular base64
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(c => {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );

        return JSON.parse(jsonPayload);
    }

    async updateToken(): Promise<void> {
        if (this.config.remoteConfig.login_version == 1) {
            await this.updateTokenV1();
        }
        if (this.config.remoteConfig.login_version == 2) {
            if (this.isAuthenticated()) {
                return Promise.resolve();
            }
            return Promise.reject()
        }
    }

    updateTokenV1(): Promise<string> {
        return new Promise((resolve, reject) => {
            if (this.afAuth.currentUser) {
                this.afAuth.currentUser.getIdToken().then(token => {
                    this.storageManager.setToken(token);
                    this.setAuthenticated(true);
                    resolve('complete');
                });
            } else {
                onAuthStateChanged(
                    getAuth(),
                    (user) => {
                        if (user) {
                            user.getIdToken().then(token => {
                                this.storageManager.setToken(token);
                                this.setAuthenticated(true);
                                resolve('complete');
                            });
                        } else {
                            if (this.storageManager.getOverrideToken() !== null) {
                                return;
                            }
                            this.storageManager.removeToken();
                            this.setAuthenticated(false);
                            reject('No user');
                        }
                    },
                    err => {
                        console.log(err);
                        this.storageManager.removeToken();
                        this.setAuthenticated(false);
                        reject(err);
                    });
            }
        });
    }

    sessionRenew$: Observable<string> = new Observable<string>();
    public updateTokenV2(): Observable<string> {

        if (this.isOverride) {
            console.log('Override Token');
            return of(this.storageManager.getOverrideToken());
        }

        // Not logged in
        if (!this.isAuthenticated()) {
            console.log('No session');
            return of(null);
        }

        const session = this.storageManager.getAuthTokenV2();

        const now = Date.now();

        // Logged in but session and refresh token expired
        if (new Date(session.refreshExpiry).getTime() < now) {
            console.log('Refresh Expired');
            return of(null);
        }

        // Logged in and session expired but can refresh
        if (new Date(session.tokenExpiry).getTime() - (3 * 60 * 60 * 1000) < now) {
            console.log('Session Expired');
            if (!this.renewingSession) {
                console.log('Refreshing Token')
                this.sessionRenew$ = this.renewV2(session).pipe(shareReplay(1), map(x => x.token), catchError(() => of(null)));
            }
            return this.sessionRenew$;
        }
        return of(session.token);
    }
}
