import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StorageManagerService } from 'src/app/services/storage-manager.service';
import { ActivatedRoute } from '@angular/router';
import { RecommendationsService } from 'src/app/services/recommendations.service';
import { ChannelResponse } from '../../models';
import { ChannelsService } from '../../services/channels.service';
import { PlaylistService } from '../../services/playlist.service';
import { VideoService } from '../../services/video.service';
import { lastValueFrom } from 'rxjs';
import { RemoteConfigService } from '../../services/remote-config.service';

@Component({
    selector: 'app-recommended-list',
    templateUrl: './recommended-list.component.html',
    styleUrls: ['./recommended-list.component.css']
})
export class RecommendedListComponent implements OnInit{

    @Input() mediaId: number;
    @Output() autoPlayToggled = new EventEmitter<boolean>();

    media: any[];
    channelId: string;
    channel: ChannelResponse;
    seasonId: number;
    order: string;
    watchList: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private storageManager: StorageManagerService,
        private channelService: ChannelsService,
        private recommendationsService: RecommendationsService,
        public config: RemoteConfigService) {
    }

    async ngOnInit() {
        this.mediaId = +this.route.snapshot.paramMap.get('id');
        this.seasonId = +this.route.snapshot.queryParamMap.get('seasonId');

        this.order = this.storageManager.playlistOrder();

        if (!this.order) {
            this.order = 'desc';
        }

        if (this.route.snapshot.queryParamMap.get('watchlist') === 'true') {
            this.watchList = true;

            let list = await this.getWatchlist();

            if (!list || list.length < 2) {
                list = await this.getRecommendations();
            }

            this.channel = {
                media: list,
                id: this.mediaId
            }
        } else {
            this.channel = {
                media: await this.getRecommendations(),
                id: this.mediaId
            };
        }
        this.setUpNextList();
    }

    async getRecommendations() {
        return await lastValueFrom(this.recommendationsService.getContentRecommendationsV5(this.mediaId, this.seasonId, this.order))
    }

    async getWatchlist() {
        const userChannels = await lastValueFrom(this.channelService.getUserChannels())
        return userChannels.find(f => f.playlistId === 'my-watchlist')?.media;
    }

    changeOrder() {
        if (this.storageManager.playlistOrder() == 'asc') {
            this.storageManager.setPlaylistOrder('desc');
        } else {
            this.storageManager.setPlaylistOrder('asc');
        }
        this.order = this.storageManager.playlistOrder();

        this.channel.media.reverse();
        this.setUpNextList();
    }

    setUpNextList() {
        if (this.channel) {
            this.media = this.channel.media;

            this.channelId = this.channel.id.toString();

            let chopIndex = this.media.findIndex(i => i.mediaId == this.mediaId);
            if (chopIndex > -1) {
                this.media = this.media.slice(chopIndex + 1, this.media.length);
            }

            if (this.media.length === 0) {
                this.media = this.channel.media;
                chopIndex = this.media.findIndex(i => i.mediaId == this.mediaId);
                this.media.splice(chopIndex, 1);
            }

            if (this.media[0]) {
                localStorage.setItem('nextVideoId', this.media[0].mediaId.toString());
                localStorage.setItem('nextVideoImage', this.media[0].images?.find(f => f.type === 'thumbnail')?.url);
            } else {
                localStorage.removeItem('nextVideoId');
            }
        } else {
            this.media = [];
        }
    }

    autoPlayToggle(event: boolean) {
        this.autoPlayToggled.emit(event);
    }
}
