<ng-container *ngIf="metadata$ | async as metadata">
    <div>
        <h2 class="video-title title-spacing">{{metadata.title}}</h2>
        <div class="video-subtitle">
            <span>{{metadata.datePublished}}</span>
        </div>
    </div>

    <div class="mt-2 mb-2">
        <div *ngIf="showShort$ | async" class="metadata-html" [innerHtml]="metadata.shortDescription">
        </div>
        <div *ngIf="!(showShort$ | async)" class="metadata-html" [innerHtml]="metadata.description">
        </div>
    </div>

    <div class="mt-2 click-div" *ngIf="metadata.shortDescription !== metadata.description">
        <div class="showmore" *ngIf="showShort$ | async" (click)="toggleDescription()">SHOW MORE</div>
        <div class="showmore" *ngIf="!(showShort$ | async)" (click)="toggleDescription()">SHOW LESS</div>
    </div>

    <div class="mb-4 d-flex justify-content-between" *ngIf="videoLoaded">
<!--        <app-rate [mediaId]="mediaId" class="published mb-0 ml-auto mr-0"></app-rate>-->

        <div class="report-button" *ngIf="safariHls"
             (click)="reportClicked.emit()" ngbTooltip="{{'report-video-playback' | translate}}">
            <svg width="208" height="208" viewBox="0 0 208 208" xmlns="http://www.w3.org/2000/svg">
                <rect x="31" y="17" width="9" height="189" fill="white"/>
                <rect x="168" y="19" width="9" height="96" fill="white"/>
                <path
                        d="M31 12V12C54.8931 5.45395 80.1069 5.45395 104 12V12V12C127.893 18.5461 153.107 18.5461 177 12V12V21V21C153.107 27.5461 127.893 27.5461 104 21V21V21C80.1069 14.4539 54.8931 14.4539 31 21V21V12Z"
                        fill="white"/>
                <path
                        d="M31 109V109C54.8931 102.454 80.1069 102.454 104 109V109V109C127.893 115.546 153.107 115.546 177 109V109V118V118C153.107 124.546 127.893 124.546 104 118V118V118C80.1069 111.454 54.8931 111.454 31 118V118V109Z"
                        fill="white"/>
            </svg>
        </div>
    </div>
</ng-container>

