<div class="main">
  <div class="container-fluid mt-3 mb-4">
    <div class="row">
      <div class="col-12 col-lg-7 col-xl-8 col-xxl-9 pr-1">
        <videojs-live *ngIf="url && ivs === false" [url]="url" [type]="video" [autoplay]="true"></videojs-live>
        <ivs-live *ngIf="url && ivs === true" [url]="url"></ivs-live>
      </div>
      <div class="col-12 col-lg-5 col-xl-4 col-xxl-3 pl-0">
        <app-chat></app-chat>
      </div>
    </div>
  </div>
</div>