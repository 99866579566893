<section class="main main-full main-responsive">
    <app-title [title]="'login-welcome-back' | translate" [subtitle]="'login-welcome-back-text' | translate">
    </app-title>
    <form (ngSubmit)="emailLogin()">
        <input [(ngModel)]="viewModel.email" type="email" class="form-control uikit-input" name="email" autofocus
            placeholder="Email" tabindex="1" />
        <app-password-field [(password)]="viewModel.password" [tabIndex]="2"></app-password-field>
        <div class="form-group block error" id="error-container">
            <div *ngFor="let errorMessage of errors">
                {{errorMessage}}
            </div>
        </div>
        <button type="submit" id="login-button" class="btn btn-lg btn-primary btn-block">{{ 'log-in' | translate | uppercase}}</button>
    </form>
    <or-block *ngIf="config.remoteConfig.login_google_enabled || config.remoteConfig.login_apple_enabled"></or-block>
    <app-brand-buttons></app-brand-buttons>
    <div id="link-text-container">
        <p>
            {{ 'login-required-doc-text' | translate }}
            <a href="/legaldocuments/terms-and-conditions" target="_blank" class="white-link p-0">{{ "terms-conditions" | translate }}</a> {{ 'and' | translate }}
            <a href="/legaldocuments/privacy-policy" target="_blank" class="white-link p-0">{{ "privacy-policy" | translate }}</a>
        </p>
        <p class="d-flex align-items-center">
            {{ 'login-forgot-password' | translate }}
            <button (click)="changePage('forgotten-password')" class="btn btn-link p-0 ml-1">{{ 'forgotten-password' | translate }}
            </button>
        </p>
        <p class="d-flex align-items-center">
            {{ 'login-no-account' | translate }}
            <button (click)="changePage('signup')" class="btn btn-link p-0 ml-1">{{ 'sign-up-here' | translate }}</button>
        </p>
    </div>
</section>
