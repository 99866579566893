import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { Language } from '../models/localisation/Language';
import { Translation } from '../models/localisation/Translation';
import { EnvironmentService } from './environment.service';
import { UiService } from './ui.service';

@Injectable({
	providedIn: 'root'
})
export class LocalisationService {

	public translationsLoaded: boolean = false;

	private selectedLanguageId: number = 4;
	public translations: Translation[];

	constructor(private http: HttpClient,
		private envService: EnvironmentService) { }

	getSelectedLanguageId(): number {
		return this.selectedLanguageId;
	}

	async setSelectedLanguageId(languageId: number): Promise<void> {
		this.selectedLanguageId = languageId;
		await this.refreshTranslations();
	}

	public getAllLanguages(): Observable<Language[]> {
		return this.http
			.get<Language[]>(`${this.envService.contentCdnHost}/v1/localisation`)
			.pipe(catchError(error => {
				console.error('LocalisationService::Error::GET', error);
				return throwError(error);
			}))
	}

	private getAllTranslationsForCurrentLanguage(): Observable<Translation[]> {
		this.translationsLoaded = false;
		return this.http
			.get<Translation[]>(`${this.envService.contentCdnHost}/v1/localisation/${this.selectedLanguageId}`)
			.pipe(catchError(error => {
				console.error('LocalisationService::Error::GET', error);
				return throwError(error);
			}))
	}

	public getTranslation(slug: string): string {
		let translation = this.translations?.find(tr => tr.stringSlug == slug);
		if (translation) {
			return translation.requiresTranslation ? translation.fallBackLanguageTranslation.text ?? '' : translation.text;
		}
		return '[]' + slug;
	}

    public getTranslationsParams(slug: string, ...args: string[]): string {
        let translation = this.getTranslation(slug);
        for (let i = 0; i < args.length; i++) {
            const replace = `\\|${i}`;
            const regex = new RegExp(replace, 'gi');
            translation = translation.replace(regex, args[i]);
        }
        return translation;
    }

	public async refreshTranslations(): Promise<void> {
		this.translations = await this.getAllTranslationsForCurrentLanguage().toPromise();
		this.translationsLoaded = true;
	}
}
