import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { StreamReport } from 'src/app/models/stream-report';
import { VideoService } from 'src/app/services/video.service';

declare var player: any;
declare var videojs: any;

@Component({
    selector: 'videojs-live',
    templateUrl: './videojs-live.component.html',
    styleUrls: ['./videojs-live.component.css']
})
export class VideojsLiveComponent implements OnInit, OnDestroy {

    @Input() url: string;

    lastReportedTime = 0;
    frequency = 5;
    isSafari: boolean = false;

    constructor(
        private videoService: VideoService) {
        this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }

    ngOnInit(): void {
    }

    ngOnDestroy() {
        player.pause();
        player.dispose();
    }

    ngAfterViewInit() {

        videojs.options.hls.overrideNative = this.isSafari;

        let vjs = 'video';

        const options = {}; // Nuevo plugin options

        player = videojs(vjs, {techOrder: ['chromecast', 'html5']});
        player.nuevo(options);
        player.chromecast({button: 'controlbar', receiverAppID: 'FD667DD2'});

        let volume = localStorage.getItem('volume');
        if (volume) {
            player.volume(volume);
        }

        player.hotkeys({
            volumeStep: 0.1,
            seekStep: 5,
            enableVolumeScroll: false,
            alwaysCaptureHotkeys: true
        });

        player.on('error', () => {
            console.log('error');
        });

        player.on('ended', () => {
            try {
                videojs(player).dispose();
            } catch {
            }
        });

        player.on('volumechange', () => {
            localStorage.setItem('volume', player.volume());
        });

        player.on('loadeddata', () => {
        });

        player.on('timeupdate', () => {
            let time = player.currentTime();
            let intTime = (time | 0);
            if (time > 0 && intTime % this.frequency == 0 && intTime > this.lastReportedTime) {
                this.lastReportedTime = intTime;
                this.videoService.reportStreamProgress(
                    'live-stream',
                    0,
                    new StreamReport(0, 0, 'StreamProgress')
                ).subscribe(res => {
                    this.videoService.setLiveCount(res.viewers);
                });
            }
        });

        player.src({
            src: this.url,
            type: 'application/x-mpegURL',
            withCredentials: false,
            overrideNative: this.isSafari,
            cacheEncryptionKeys: true
        });
    }
}
