import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { Router } from '@angular/router';
import { LiveService } from 'src/app/services/live.service';

@Component({
    selector: 'app-live',
    templateUrl: './live.component.html',
    styleUrls: ['./live.component.css']
})
export class LiveComponent implements OnInit {

    ivs: boolean = false;
    url: string;

    constructor(
        private app: AppComponent,
        private router: Router,
        private liveService: LiveService) {
    }

    ngOnInit() {
        this.app.startLoading();
        this.liveService.getLiveVideo().subscribe(res => {
            this.url = res.url;
            this.ivs = res.ivs;
            this.app.stopLoading();
        });
    }

}
