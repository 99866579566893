import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../services/account.service';
import { StorageManagerService } from '../services/storage-manager.service';

declare let Android: any;
declare let window: any;

@Component({
    template: ''
})
export abstract class AuthenticationOverride implements OnInit {

    constructor(
        protected route: ActivatedRoute,
        protected storageManager: StorageManagerService,
        protected router: Router,
        protected account: AccountService) {
    }

    ngOnInit(): void {
        if (this.route.snapshot.url.some(x => x.path.includes('greenlight'))) {
            this.initGreenlight();
            return;
        }

        if (!this.account.isAuthenticated()) {
            const accessToken = this.route.snapshot.queryParamMap.get('token');
            console.log(accessToken);
            if (accessToken) {
                this.storageManager.setOverrideToken(accessToken);
                this.account.setAuthenticated(true);
            } else {
                if (this.hideMenus) {
                    this.router.navigate(['/something-went-wrong', {'hidemenus': this.hideMenus}]);
                } else {
                    this.storageManager.setAfterLoginUrl(this.router.url);
                    this.account.nextRoute = this.router.url;
                    this.router.navigate(['/login']);
                }
                return;
            }
        }
    }

    private initGreenlight() {
        const accessToken = this.route.snapshot.queryParamMap.get('token');
        if (accessToken) {
            this.storageManager.setOverrideToken(accessToken);
            this.account.setAuthenticated(true);
        }
    }

    get hideMenus() {
        return this.route.snapshot.queryParamMap.get('hidemenus') || this.isMobile;
    }

    get isMobile() {
        return this.isAndroidApp || this.isIosApp;
    }

    get isIosApp() {
        if (this.storageManager.getDevice() === 'ios') {
            return true;
        }

        const isUIWebView = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent);
        // For WKWebView detection
        const isWKWebView = /(iPhone|iPod|iPad).*AppleWebKit/i.test(navigator.userAgent) && !window.indexedDB;

        return isUIWebView || isWKWebView || !!window?.webkit?.messageHandlers?.WVHandler;
    }

    get isAndroidApp() {
        if (this.storageManager.getDevice() === 'android') {
            return true;
        }
        try {
            return Android !== undefined && Android !== null;
        } catch {
            return false;
        }
    }

    back(link: string) {
        if (this.isIosApp) {
            window.webkit.messageHandlers.WVHandler.postMessage('back');
            return;
        }

        if (this.isAndroidApp) {
            Android.back();
            return;
        }

        this.router.navigate([link]);
    }

    onComplete() {
        if (this.isIosApp) {
            window.webkit.messageHandlers.WVHandler.postMessage('complete');
        }

        if (this.isAndroidApp) {
            Android.onComplete();
        }
    }

    requiresLogin() {
        if (this.isIosApp) {
            window.webkit.messageHandlers.WVHandler.postMessage('requiresLogin');
        }

        if (this.isAndroidApp) {
            Android.requiresLogin();
        }
    }

    playVideoMobile(dash: string, hls: string) {
        if (this.isIosApp && hls?.length > 0) {
            window.webkit.messageHandlers.WVHandler.postMessage('playVideo - ' + hls);
        }

        if (this.isAndroidApp && dash?.length > 0) {
            Android.playVideo(dash);
        }
    }

    mediaRedirectMobile(mediaId: number) {
        if (this.isIosApp) {
            window.webkit.messageHandlers.WVHandler.postMessage('mediaRedirect - ' + mediaId);
        }

        if (this.isAndroidApp) {
            Android.mediaRedirect(mediaId);
        }
    }

    seasonRedirectMobile(seasonId: number) {
        if (this.isIosApp) {
            window.webkit.messageHandlers.WVHandler.postMessage('seasonRedirect - ' + seasonId);
        }

        if (this.isAndroidApp) {
            Android.seasonRedirect(seasonId);
        }
    }

    downloadRedirectMobile() {
        if (this.isIosApp) {
            window.webkit.messageHandlers.WVHandler.postMessage('downloadsRedirect');
        }

        if (this.isAndroidApp) {
            Android.downloadsRedirect();
        }
    }

    logout() {
        if (this.isIosApp) {
            window.webkit.messageHandlers.WVHandler.postMessage('logout');
            return;
        }

        if (this.isAndroidApp) {
            Android.logout();
            return;
        }

        this.account.logoutUser();
    }

    downloadWifiOnlyMobile(value: boolean) {
        if (this.isIosApp) {
            window.webkit.messageHandlers.WVHandler.postMessage('downloadWifiOnly - ' + value.toString());
            return;
        }

        if (this.isAndroidApp) {
            Android.downloadWifiOnly(value);
            return;
        }
    }

}
