import {Injectable} from '@angular/core';
import {Observable, Observer, of, switchMap, take, throwError} from 'rxjs';
import io from 'socket.io-client';
import {environment} from 'src/environments/environment';
import {StorageManagerService} from './storage-manager.service';
import {RemoteConfigService} from './remote-config.service';
import {SocketIoCommentMessage} from '../models/socket-io.models';
import {AccountService} from './account.service';
import {catchError} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SocketService {
    private socket: any;
    private url: string = environment.socketUrl;

    constructor(
        private storageManagerService: StorageManagerService,
        private config: RemoteConfigService,
        private accountService: AccountService
    ) {
        if (this.config.remoteConfig.comments_version !== '1') {
            if (this.config.remoteConfig.login_version == 1) {
                const tokenQuery = `token=${this.storageManagerService.getToken()}`;
                this.socket = io(`${this.url}?${tokenQuery}`);
            } else if (this.config.remoteConfig.login_version == 2) {
                this.accountService.updateTokenV2().pipe(
                    take(1),
                    switchMap(token => {
                        this.socket = io(`${this.url}?token=${token}`);
                        return of(this.socket); // To complete the Observable sequence
                    }),
                    catchError(err => {
                        return throwError(err); // Rethrow the error
                    })
                ).subscribe(); // Initiating the Observable sequence
            }
        }
    }

    sendMessage(route: string, message: any): void {
        this.socket.emit(route, JSON.stringify(message));
    }

    sendComment(message: string, mediaId: number, originalChatId: number = undefined): void {
        const ioMessage: SocketIoCommentMessage = {
            message: message,
            mediaId: mediaId,
            type: 'message'
        };
        if (originalChatId) {
            ioMessage.originalCommentId = originalChatId;
        }
        this.sendMessage('comment', ioMessage);
    }

    addLike(commentId: number, mediaId: number) {
        const message = { type: 'add-like', commentId, mediaId };
        this.sendMessage('comment', message);
    }

    removeLike(commentId: number, mediaId: number) {
        const message = { type: 'remove-like', commentId, mediaId };
        this.sendMessage('comment', message);
    }

    get(route: string, mediaId: number, lowestCommentId: number = undefined): void {
        const message: any = {
            type: 'get', mediaId: mediaId
        };

        if (lowestCommentId !== undefined) {
            message.lowestCommentId = lowestCommentId;
        }

        this.socket.emit(route, JSON.stringify(message));
    }

    deleteMessage(route: string, mediaId: number, commentId: number): void {
        this.socket.emit(route, JSON.stringify({type: 'delete', mediaId: mediaId, commentId}));
    }

    onMessage(route: string): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.socket.on(route, (data: any) => {
                observer.next(data);
            });
        });
    }

    disconnect(): void {
        if (this.socket && this.socket.connected) {
            this.socket.disconnect();
        }
    }
}
