import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Auth, GoogleAuthProvider, OAuthProvider, signInWithPopup, updateProfile} from '@angular/fire/auth';
import {AppComponent} from '../../../app.component';
import {AccountService} from '../../../services/account.service';
import {RemoteConfigService} from '../../../services/remote-config.service';
import {environment} from '../../../../environments/environment';

declare const google: any;
declare const AppleID: any;

@Component({
    selector: 'app-brand-buttons',
    templateUrl: './brand-buttons.component.html',
    styleUrls: ['./brand-buttons.component.scss']
})
export class BrandButtonsComponent implements OnInit {
    errorMessage: string;
    errors: any[];
    nextRoute: string;
    viewModel: any = {};
    @Input() disableButtons: boolean;
    @Input() isTVSignup: boolean = false;
    @Input() displayName: string;

    @Output() error = new EventEmitter<string>();

    constructor(
        private afAuth: Auth,
        private app: AppComponent,
        private accountService: AccountService,
        public config: RemoteConfigService) {
        this.app.stopLoading();
    }


    ngOnInit(): void {
        if (this.config.remoteConfig.login_version == 2) {
            if (this.config.remoteConfig.login_google_enabled) {
                console.log('Rendering Google Button - Client Id: ' + environment.google.client_id);
                google.accounts.id.initialize({
                    client_id: environment.google.client_id,
                    callback: this.googleLogin
                });
                google.accounts.id.renderButton(
                    document.getElementById('googleBtn'),
                    {
                        theme: 'outline',
                        size: 'large',
                        text: 'continue_with',
                        width: document.getElementById('block-container').offsetWidth,
                    }
                );
            }

            if (this.config.remoteConfig.login_apple_enabled) {
                AppleID.auth.init({
                    redirectURI: 'https://www.pepperbox.tv/',
                    clientId: environment.apple.client_id,
                    scope: environment.apple.scope,
                    state: environment.apple.state,
                    nonce: environment.apple.nonce,
                    usePopup: true
                });
            }
        }
    }

    async appleLogin() {
        if (this.config.remoteConfig.login_version == 1) {
            this.nextRoute = this.isTVSignup ? '/tv' : this.accountService.nextRoute;
            const provider = new OAuthProvider('apple.com');
            provider.addScope('email');
            provider.addScope('name');
            await this.signInProcess(provider);
        }
        if (this.config.remoteConfig.login_version == 2) {
            this.app.startLoading();

            try {
                const data = await AppleID.auth.signIn();
                this.accountService.loginAppleV2(data.authorization.id_token, this.displayName).subscribe({
                    next: () => {
                        this.app.sharedLogin(undefined, this.nextRoute);
                    },
                    error: (error) => {
                        console.log(error);

                        if (error.status === 400) {
                            this.error.emit(error.error);
                        }

                        this.app.stopLoading();
                    }
                });
            } catch (e) {
                this.app.stopLoading();
            }
        }

    }


    async googleLoginV1() {
        await this.signInProcess(new GoogleAuthProvider());
    }

    googleLoginV2(response: any) {
        this.accountService.loginGoogleV2(response.credential, this.displayName).subscribe({
            next: () => {
                this.app.sharedLogin(undefined, this.nextRoute);
            },
            error: (error) => {
                console.log(error);

                if (error.status === 400) {
                    this.error.emit(error.error);
                }

                this.app.stopLoading();
            }
        });
    };

    googleLogin = async (response?: any) => {
        this.app.startLoading();
        this.nextRoute = this.isTVSignup ? '/tv' : this.accountService.nextRoute;
        if (this.config.remoteConfig.login_version == 1) {
            await this.googleLoginV1();
        }
        if (this.config.remoteConfig.login_version == 2) {
            this.googleLoginV2(response);
        }
    };

    async signInProcess(provider: OAuthProvider | GoogleAuthProvider) {
        await signInWithPopup(this.afAuth, provider)
            .then(result => {
                if (!result.user.photoURL) {
                    updateProfile(result.user, {
                        photoURL: this.config.remoteConfig.default_avatar
                    });
                }
                this.app.sharedLogin(result, this.nextRoute);
            })
            .catch(error => {
                console.log(error);
                this.app.stopLoading();
            });
    }

}
