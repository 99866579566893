import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectsService } from '../../services/projects.service';
import {
    BehaviorSubject,
    interval,
    map,
    Observable,
    startWith,
    Subject,
    Subscription, take,
    takeUntil,
    tap
} from 'rxjs';
import { ProjectVsResponse } from '../../models/project-page/v5/project-vs-response';
import { UiService } from '../../services/ui.service';
import { WebSocketService } from '../../services/websocket.service';
import { ScreenSizeEnum } from '../../models/screen-size.enum';
import { AccountService } from '../../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageManagerService } from '../../services/storage-manager.service';
import { AuthenticationOverride } from '../../modules/authentication-override';
import { ProjectResponseV5 } from '../../models';
import { PointsService } from '../../services/points.service';
import { UserInfoService } from '../../services/user-info.service';

@Component({
    selector: 'app-projects-vs',
    templateUrl: './projects-vs.component.html',
    styleUrls: ['./projects-vs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectsVsComponent extends AuthenticationOverride implements OnInit, OnDestroy {

    totalPoints: number = 0;
    numberAnimSubLeft: Subscription;
    numberAnimSubRight: Subscription;
    pastProjectLength = 0;
    shownSliderItems = 6;

    projects$: Observable<ProjectVsResponse>;
    pastProjects$: Observable<Winner[]>;
    pointsLeft$: BehaviorSubject<PointsBS> = new BehaviorSubject<PointsBS>({id: 0, points: 0});
    pointsRight$: BehaviorSubject<PointsBS> = new BehaviorSubject<PointsBS>({id: 0, points: 0});
    isMobileView$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    voteTitle$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    voteId$: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);
    pastWinnerTransformIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    countdown$: Observable<{ label: string, time: string }> = new Observable<{ label: string, time: string }>();
    active$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    producterPoints$: Observable<number>;

    get pastWinnerTransformIndex() {
        return this.pastWinnerTransformIndex$.value;
    }

    set pastWinnerTransformIndex(val: number) {
        this.pastWinnerTransformIndex$.next(val);
    }

    destroy$: Subject<void> = new Subject<void>();

    constructor(private service: ProjectsService,
                private ui: UiService,
                private websockets: WebSocketService,
                private pointsService: PointsService,
                private userInfoService: UserInfoService,
                public account: AccountService,
                public router: Router,
                public storage: StorageManagerService,
                route: ActivatedRoute) {
        super(route, storage, router, account);

        this.ui.breakpointObservable
            .pipe(
                takeUntil(this.destroy$)
            )
            .subscribe((screenSize: ScreenSizeEnum) => {
                this.isMobileView$.next(screenSize <= ScreenSizeEnum.Medium);
                this.shownSliderItems = this.isMobileView$.value ? 2 : 6;
                this.pastWinnerTransformIndex = 0;
            });

        this.projects$ = this.service.getActiveProjectsV5()
            .pipe(
                tap(x => {
                        this.pointsLeft$.next({id: x.leftProject.id, points: x.leftProject.points});
                        this.pointsRight$.next({id: x.rightProject.id, points: x.rightProject.points});
                        this.totalPoints = x.leftProject.points + x.rightProject.points;
                        this.countdown$ = this.getCountdown(Date.parse(x.startDate + 'Z'), Date.parse(x.endDate + 'Z'));
                        this.active$.next(x.active);
                    }
                )
            );

        this.pastProjects$ = this.service.getPastProjectsV5()
            .pipe(
                map(x => this.getProjectImages(x)),
                tap(x => {
                    this.pastProjectLength = x.length;
                })
            );

        this.producterPoints$ = this.pointsService.points$.asObservable();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.websockets.greenlightPoints
            .pipe(takeUntil(this.destroy$))
            .subscribe(x => {
                for (let p of x) {
                    this.updatePoints(p.ProjectId, p.Total);
                }
            });
        this.websockets.join('greenlight');

        if (this.isMobile || true) {
            this.userInfoService.getUserInfoQuery('points').subscribe(x => {
                this.pointsService.producerPoints = x.pointsAvailable;
            });
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
        this.websockets.leave('greenlight');
    }

    getCountdown(startDate: number, endDate: number): Observable<{ label: string, time: string }> {
        const intervalMS = 1000;
        return interval(intervalMS).pipe(
            startWith(0),
            map(() => {
                const now = new Date().getTime();
                let diff: number;
                let label: string;

                if (now < startDate) {
                    label = 'Starts In';
                    diff = Math.floor((startDate - now) / intervalMS);
                    this.active$.next(false);
                } else if (now < endDate) {
                    label = 'Ends In';
                    diff = Math.floor((endDate - now) / intervalMS);
                    this.active$.next(true);
                } else {
                    return {
                        label: '',
                        time: ''
                    };
                }

                const days = Math.floor(diff / (3600 * 24));
                const hours = Math.floor((diff % (3600 * 24)) / 3600);
                const minutes = Math.floor((diff % 3600) / 60);
                const seconds = diff % 60;

                let timeUntil = '';

                if (days > 0) {
                    timeUntil = `${days}d `;
                }

                return {
                    label: label,
                    time: timeUntil + `${hours.toString().padStart(2, '0')}h ${minutes.toString().padStart(2, '0')}m ${seconds.toString().padStart(2, '0')}s`
                };
            }),
        );
    }

    getVideo(project: ProjectResponseV5, format: string = 'dash'): string {
        return project.videos
            ?.find(x => x.format === format)
            ?.url;
    }

    getImage(project: ProjectResponseV5, type: string = 'packshot'): string {
        return project.images
            ?.find(x => x.type === type)
            .url;
    }

    getProjectImages(projects: ProjectResponseV5[]): Winner[] {
        return projects.map(x => {
            return {url: this.getImage(x, 'packshot-vertical'), mediaId: x.mediaId, seasonId: undefined};
        });
    }

    percentage(points: number): number {
        return (100 * points) / this.totalPoints;
    }

    openContribute(project: ProjectResponseV5) {
        if (this.isAuthenticated()) {
            this.voteTitle$.next(project.title);
            this.voteId$.next(project.id);
            this.service.openPointContribution(project.id);
            return;
        }

        if (this.isMobile) {
            this.requiresLogin();
            return;
        }

        this.storage.setAfterLoginUrl('/greenlight');
        this.router.navigate(['/login']);
    }

    updatePoints(id: number, points: number) {
        const left = this.pointsLeft$.value;
        if (left.id === id) {
            this.doAnimateNumber(left.points, points, 'left');
        }

        const right = this.pointsRight$.value;
        if (right.id === id) {
            this.doAnimateNumber(right.points, points, 'right');
        }
    }

    doAnimateNumber(from: number, to: number, side: string, duration: number = 200) {
        if (side === 'left') {
            this.numberAnimSubLeft?.unsubscribe();
            this.numberAnimSubLeft = this.animateNumber(from, to, duration, side);
        } else {
            this.numberAnimSubRight?.unsubscribe();
            this.numberAnimSubRight = this.animateNumber(from, to, duration, side);
        }
    }

    animateNumber(from: number, to: number, duration: number, side: string, steps: number = 11): Subscription {
        if (from === to) {
            return;
        }

        const stepTime = Math.abs(Math.floor(duration / steps));
        const stepValue = (to - from) / steps;
        const range = Array(steps).fill(0).map((x, i) => from + (stepValue * i) + 1);

        return interval(stepTime).pipe(
            take(range.length),
            takeUntil(this.destroy$),
            map((i: number) => {
                if (i === steps - 1) {
                    return to;
                }

                return Math.floor(range[i]);
            }))
            .subscribe(num => {
                let a: PointsBS;
                if (side === 'left') {
                    a = this.pointsLeft$.value;
                    a.points = num;
                    this.pointsLeft$.next(a);
                } else {
                    a = this.pointsRight$.value;
                    a.points = num;
                    this.pointsRight$.next(a);
                }
                this.totalPoints = this.pointsRight$.value.points + this.pointsLeft$.value.points;
            });
    }

    isAuthenticated(): boolean {
        return this.account.isAuthenticated();
    }

    get right(): boolean {
        return this.pastWinnerTransformIndex !== Math.ceil(this.pastProjectLength / this.shownSliderItems) - 1;
    }

    get left(): boolean {
        return this.pastWinnerTransformIndex !== 0;
    }

    navRight() {
        const newIndex = this.pastWinnerTransformIndex + 1;
        const minIndex = Math.ceil(this.pastProjectLength / this.shownSliderItems) - 1;
        this.pastWinnerTransformIndex = Math.min(newIndex, minIndex);
    }

    navLeft() {
        this.pastWinnerTransformIndex = Math.max(this.pastWinnerTransformIndex - 1, 0);
    }

    pointer(winner: Winner) {
        return winner.mediaId !== undefined || winner.seasonId !== undefined;
    }

    pastWinnerClicked(winner: Winner) {
        if (!winner.mediaId && !winner.seasonId) {
            return;
        }

        if (this.isMobile && winner.mediaId) {
            this.mediaRedirectMobile(winner.mediaId);
            return;
        }

        if (this.isMobile && winner.seasonId) {
            this.seasonRedirectMobile(winner.seasonId);
            return;
        }

        if (winner.seasonId) {
            this.router.navigate(['/season', winner.seasonId]);
        }

        if (winner.mediaId) {
            this.router.navigate(['/video', winner.mediaId]);
        }

    }
}

interface PointsBS {
    id: number;
    points: number;
}

interface Winner {
    url: string,
    mediaId: number | undefined,
    seasonId: number | undefined
}
