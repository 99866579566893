<div id="recommendation-list" class="mt-3">
    <div class="recommendation-list-title">
        <h4 class="d-inline-block">Up next</h4>

        <div class="pointer mb-1 ml-2" (click)="changeOrder()" *ngIf="order === 'asc' && media && media.length > 0"  placement="bottom" ngbTooltip="Ascending">
            <svg viewBox="0 0 238 195" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M127 66L167 26L207 66" stroke-width="16" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M167 122V26" stroke-width="16" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M31 106H103" stroke-width="16" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M31 42H87" stroke-width="16" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M31 170H167" stroke-width="16" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>

        <div class="pointer mb-1 ml-2" (click)="changeOrder()" *ngIf="order === 'desc' && media && media.length > 0" placement="bottom" ngbTooltip="Descending">
            <svg viewBox="0 0 238 195" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M207 82L167 122L127 82" stroke-width="16" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M167 26V122" stroke-width="16" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M31 106H103" stroke-width="16" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M31 42H87" stroke-width="16" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M31 170H167" stroke-width="16" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>

        <div class="d-inline-block switch-right">
            <app-up-next-switch (autoPlayChanged)="autoPlayToggle($event)"></app-up-next-switch>
        </div>
        <div class="float-none"></div>
    </div>
    <ng-container *ngFor='let m of media; let index = index'>
        <div class="mb-2">
            <app-video-thumb-v2 [fromWatchList]="watchList" [media]="m" [seasonId]="seasonId" [hoverOnSmall]="false"></app-video-thumb-v2>
        </div>
    </ng-container>

    <a routerLink="/">
        <img class="w-100" *ngIf="media && media.length == 0"
            src="{{config.remoteConfig.loading_packshot}}" alt="Up Next - Channels" />
    </a>
</div>
