import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { StorageManagerService } from 'src/app/services/storage-manager.service';
import { AccountService } from 'src/app/services/account.service';
import { environment } from 'src/environments/environment';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    constructor(
        private app: AppComponent,
        private authenticationService: AccountService,
        private subscriptionService: SubscriptionService,
        private router: Router,
        storageManager: StorageManagerService) {
        app.stopLoading();
        storageManager.removeString('recommended-list');

        storageManager.setPage('home');
    }

    ngOnInit() {
    }

}
