import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError, Observable, map, shareReplay, BehaviorSubject, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { EnvironmentService } from './environment.service';
import { Emoji } from '../models/emoji';
import { EmojiCustom } from '../models/emoji-custom';
import { Comment, CommentResponse } from '../models/comment';

@Injectable({
    providedIn: 'root'
})
export class ChatService {

    replying: boolean = false;
    emoji: boolean = false;

    customEmojis$: Observable<EmojiCustom[]>;
    uidToUsernameMap: Map<string, string> = new Map();
    usernameToUidMap: Map<string, string> = new Map();

    private _replyingComment$: BehaviorSubject<Comment> = new BehaviorSubject<Comment>(null);
    get replyingComment$(): Observable<Comment> {
        return this._replyingComment$.asObservable();
    }
    get replyingComment(): Comment {
        return this._replyingComment$.value;
    }
    set replyingComment(value: Comment) {
        this._replyingComment$.next(value);
    }

    constructor(
        private envService: EnvironmentService,
        private http: HttpClient) {
    }

    sendMessage(mediaId: string, chat: any, originalChatId: string): Observable<any> {
        chat.originalChatId = originalChatId;
        return this.http
            .post<any>(this.envService.userHost + '/v1/chat/' + mediaId, chat).pipe(
            );
    }

    deleteMessage(mediaId: string, chatId: string, originalChatId: string) {
        let url = this.envService.userHost + '/v1/chat/' + mediaId + '/' + chatId;
        if (originalChatId) {
            url += '/' + originalChatId;
        }
        return this.http
            .delete(url)
            .pipe(catchError(error => {
                if (!environment.production) {
                    console.error('ChatService::Error::DELETE', error);
                }
                return throwError(error);
            }));
    }

    reportMessage(mediaId: string, chatId: string) {
        const url = this.envService.userHost + '/v1/chat/' + mediaId + '/' + chatId + '/report';
        return this.http
            .patch(url, {})
            .pipe(catchError(error => {
                if (!environment.production) {
                    console.error('ChatService::Error::DELETE', error);
                }
                return throwError(error);
            }));
    }

    getEmojis(): Observable<EmojiCustom[]> {

        if (!this.customEmojis$) {
            this.customEmojis$ = this.http.get<Emoji[]>(`${this.envService.contentHost}/v1/emojis`)
                .pipe(
                    shareReplay(1),
                    map(emojis => {
                        return emojis.map(emoji => {
                            const keyword = emoji.keyword.replace(/:/g, '');
                            return {
                                name: emoji.name,
                                imageUrl: emoji.url,
                                keywords: [keyword],
                                shortNames: [keyword],
                                text: '',
                                emoticons: []
                            };
                        });
                    }));
        }
        return this.customEmojis$;
    }

    getComments(mediaId: number, lastComment: string = undefined): Observable<{comments: Comment[], lastPage: boolean}> {
        let url = `${this.envService.userHost}/v3/comments/${mediaId}`;
        if (lastComment) {
            url += '?lowestcommentid=' + lastComment;
        }

        return this.http.get<{comments: Comment[], lastPage: boolean}>(url)
            .pipe(
                tap(res => {
                    res.comments.forEach(comment => {
                        this.uidToUsernameMap.set(comment.uid, comment.name ?? comment.displayName);
                        this.usernameToUidMap.set(comment.name ?? comment.displayName, comment.uid);
                    })
                })
            );
    }

    getTopComments(mediaId: number, lastComment: string = undefined): Observable<CommentResponse> {
        let url = `${this.envService.userHost}/v3/comments/${mediaId}/popular`;
        if (lastComment) {
            url += '?lowestcommentid=' + lastComment;
        }

        return this.http.get<CommentResponse>(url)
            .pipe(
                tap(res => {
                    res.comments.forEach(comment => {
                        this.uidToUsernameMap.set(comment.uid, comment.name ?? comment.displayName);
                        this.usernameToUidMap.set(comment.name ?? comment.displayName, comment.uid);
                    })
                })
            );
    }
}
