import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { AccountService } from 'src/app/services/account.service';
import { PointsService } from 'src/app/services/points.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { DeleteRequest } from 'src/app/models/delete-request';
import { UserInfoService } from '../../../services/user-info.service';
import { RemoteConfigService } from '../../../services/remote-config.service';
import { AuthenticationOverride } from '../../../modules/authentication-override';
import { StorageManagerService } from '../../../services/storage-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Utils } from '../../../services/utils.service';
import { UserPreferencesService } from '../../../services/user-preferences.service';
import { UserPreferenceDefaults, UserPreferenceKey } from '../../../models/enums/user-preferences.enum';

@Component({
    selector: 'app-account-v2',
    templateUrl: './account-v2.component.html',
    styleUrls: ['./account-v2.component.scss']
})
export class AccountV2Component extends AuthenticationOverride implements OnInit, OnDestroy {

    loading: boolean;
    pointsLoading: boolean;
    subscriptionLoading: boolean;

    appParam: 'ios' | 'android';
    badgeUrl: string;
    displayName: string;
    email: string;
    plan: string;
    producerPoints: number;
    producerPointsAllTime: number;
    subscriptionCancelled: boolean;
    subscriptionEndDate: string;
    subscriptionType: string;
    trialHours: number;
    hasSubscription: boolean;
    hasPatreon = false;
    deleteRequest: DeleteRequest = null;
    userId: number;
    isInTrial: boolean;
    canUpdatePlan: boolean;
    userVerified: boolean = false;

    downloadsEnabled = false;
    downloadOverWifi: boolean = UserPreferenceDefaults.DOWNLOADS_WifiOnly;
    downloadsLoading: boolean = true;

    destroy$ = new Subject<void>();

    constructor(
        private accountService: AccountService,
        private pointsService: PointsService,
        private subscriptionService: SubscriptionService,
        public app: AppComponent,
        private userPreferencesService: UserPreferencesService,
        private userInfoService: UserInfoService,
        public config: RemoteConfigService,
        storageManager: StorageManagerService,
        public route: ActivatedRoute,
        router: Router) {
        super(route, storageManager, router, accountService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.init();
    }

    async init() {
        this.app.stopLoading();
        this.loading = true;

        if (this.isMobile) {
            this.downloadsEnabled = Boolean(this.route.snapshot.queryParamMap.get('downloadsEnabled'));
        }

        const res = await lastValueFrom(this.userInfoService.getUserInfoQuery('badges,patreon,display-name,email,points,subscription,subscription-status,delete-request,userid,subscription-extended,verified'));
        // badges
        this.badgeUrl = res.badgeUrl + '?u=' + new Date().getTime();
        // display-name
        this.displayName = res.displayName;
        // points
        this.producerPoints = res.pointsAvailable;
        this.producerPointsAllTime = res.pointsGained;
        // email
        this.email = res.email;
        // subscription
        this.plan = res.plan;
        this.subscriptionEndDate = res.subscriptionEndDate;
        this.trialHours = res.streamsRemaining;
        // subscription-status
        this.subscriptionCancelled = res.subscriptionCancelled;
        this.hasSubscription = res.hasSubscription;
        this.subscriptionType = res.subscriptionType;
        this.hasPatreon = res.hasPatreon;
        this.deleteRequest = res.deleteRequest;
        this.userId = res.userId;
        this.isInTrial = res.isInTrial;
        this.canUpdatePlan = res.canUpdatePlan;
        this.userVerified = res.verified;

        this.userPreferencesService.getOne(UserPreferenceKey.DOWNLOADS_WifiOnly).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: downloadOverWifi => {
                    if (downloadOverWifi && downloadOverWifi?.value !== undefined) {
                        this.downloadOverWifi = downloadOverWifi.value === 'true';
                    }
                    this.downloadsLoading = false;
                },
                error: () => {
                    this.downloadsLoading = false;
                }
            });

        this.appParam = this.storageManager.getDevice();
        this.loading = false;
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    isNullOrWhitespace(str: string): boolean {
        return Utils.isNullOrWhitespace(str);
    }

    recalculatePP() {
        if (this.pointsLoading) {
            return;
        }
        this.pointsLoading = true;
        this.pointsService.recalculatePoints().subscribe(() => {
            this.userInfoService.getUserInfoQuery('points').subscribe(res => {
                // Set points for both account and menu
                this.producerPoints = res.pointsAvailable;
                this.producerPointsAllTime = res.pointsGained;
                this.pointsService.producerPoints = res.pointsAvailable;

                this.pointsLoading = false;
            });
        });
    }

    restartSubscription() {
        if (this.subscriptionLoading) {
            return;
        }
        this.subscriptionLoading = true;
        this.subscriptionService.resumeLatestSubscription().subscribe({
            next: () => {
                this.userInfoService.getUserInfoQuery('subscription-status').subscribe(res => {
                    // subscription-status
                    this.subscriptionCancelled = res.subscriptionCancelled;

                    this.subscriptionLoading = false;
                });
            },
            error: () => {
                // PRINT: SOMETHING WENT WRONG
                this.userInfoService.getUserInfoQuery('subscription-status').subscribe(res => {
                    // subscription-status
                    this.subscriptionCancelled = res.subscriptionCancelled;

                    this.subscriptionLoading = false;
                });
            }
        });
    }

    subscribe() {
        this.accountService.navigateToAccessPage('payment');
    }

    cancelDownloadUpdate$ = new Subject<void>();
    updateDownloadOverWifi($event: boolean) {
        this.cancelDownloadUpdate$.next();
        this.userPreferencesService.set(UserPreferenceKey.DOWNLOADS_WifiOnly, $event.toString()).pipe(takeUntil(this.cancelDownloadUpdate$))
            .subscribe(() => {
                this.downloadWifiOnlyMobile($event)
            });
    }
}
