import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { EnvironmentService } from './environment.service';
import { MetadataResponseV2 } from '../models';

@Injectable({
    providedIn: 'root'
})
export class MetadataService {

    constructor(
        private envService: EnvironmentService,
        private http: HttpClient) {
    }

    getMetadata(mediaId: number): Observable<MetadataResponseV2> {
        return this.http
            .get<MetadataResponseV2>(this.envService.contentCdnHost + '/v2/metadata/' + mediaId)
            .pipe(catchError(error => {
                if (!environment.production)
                    console.error('MetadataService::Error::GET', error);
                return throwError(error);
            }));
    }
}
